import { useEffect, useContext, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import axiosClient from "../../../config/axios";
import AuthContext from "../../../context/auth/authContext";

function NotificarPago() {
  const authContext = useContext(AuthContext);
  const { header, userID, getUser, checkGrupo, auth, username } = authContext;
  let location = useLocation();
  const preference_id = new URLSearchParams(location.search).get(
    "preference_id"
  );
  const payment_id = new URLSearchParams(location.search).get("payment_id");
  const estado_pago = new URLSearchParams(location.search).get(
    "collection_status"
  );

  const [loadingPago, setLoadingPago] = useState(true);
  const [factura, setFactura] = useState(null);
  const [hayError, setHayError] = useState(false);

  // Funcion para formatear el importe
  const formatImporte = (data) => {
    const total = new Intl.NumberFormat("de-DE", {
      minimumFractionDigits: 2,
    }).format(data);

    return "$ " + total;
  };

  if (preference_id === null || payment_id === null) {
    window.location.href = "/login";
  }

  useEffect(() => {
    getUser();
  }, []);

  useEffect(() => {
    if (auth) {
      var payload = {
        nombre_cliente: process.env.REACT_APP_NOMBRE_CLIENTE,
        id_preferencia: preference_id,
        id_pago: payment_id,
      };
      axiosClient
        .post("notificacion_pago", payload, header)
        .then((response) => {
          if (response.data.data.message === "El pago no fue procesado") {
            setLoadingPago(false);
          } else {
            setFactura(response.data.data);
          }
        })
        .catch((err) => {
          console.log(err);
          setHayError(true);
          setLoadingPago(false);
        });
    }
  }, [auth]);

  useEffect(() => {
    if (factura) {
      setLoadingPago(false);
    }
  }, [factura]);

  return (
    <div>
      <div className="rounded-lg shadow-lg my-12 m-4 bg-white border border-gray-800">
        <div className="bg-indigo-600 rounded rounded-b-none w-full py-12"></div>
        <div className="bg-white rounded w-full flex justify-center py-12">
          <div className="rounded-full bg-green-500 shadow-md border border-gray-800 w-24 h-24 bg-cover flex justify-center items-center -mt-24">
            <svg className="w-20 h-20 text-white" viewBox="0 0 24 24">
              <path
                d="M20 4H4c-1.11 0-2 .89-2 2v12a2 2 0 0 0 2 2h16c1.11 0 2-.89 2-2V6a2 2 0 0 0-2-2m-5 6h-4v1h3c.55 0 1 .45 1 1v3c0 .55-.45 1-1 1h-1v1h-2v-1H9v-2h4v-1h-3c-.55 0-1-.45-1-1V9c0-.55.45-1 1-1h1V7h2v1h2v2z"
                fill="currentColor"
              ></path>
            </svg>
          </div>
        </div>
        <div className="flex justify-center pb-12 pt-8">
          <div className="flex justify-center items-center -mt-8 py-8 bg-indigo-200 border border-indigo-600 rounded w-1/2 shadow-xl flex-col">
            <p className="text-3xl font-bold text-gray-800 mb-4">
              {loadingPago
                ? "Estamos verificando tu pago"
                : factura
                ? "Pago aprobado exitosamente"
                : !hayError && estado_pago === "in_process"
                ? "Pago pendiente"
                : "Hubo un error verificando el Pago"}
            </p>
            {loadingPago ? (
              <svg
                className="w-16 h-16 animate-spin text-indigo-700 mt-4 mb-4"
                viewBox="0 0 24 24"
              >
                <path
                  d="M12 4V2A10 10 0 0 0 2 12h2a8 8 0 0 1 8-8z"
                  fill="currentColor"
                ></path>
              </svg>
            ) : factura ? (
              <div className="bg-white rounded-xl mt-4 mb-4 px-12 flex justify-center flex-col items-center border border-gray-800">
                <svg className="w-16 h-16 text-green-500" viewBox="0 0 24 24">
                  <path
                    d="M3 6v12h10.32a6.38 6.38 0 0 1-.32-2H7a2 2 0 0 0-2-2v-4c1.11 0 2-.89 2-2h10a2 2 0 0 0 2 2v.06c.67 0 1.34.12 2 .34V6H3m9 3c-1.7.03-3 1.3-3 3s1.3 2.94 3 3c.38 0 .77-.08 1.14-.23c.27-1.1.72-2.14 1.83-3.16C14.85 10.28 13.59 8.97 12 9m9.63 3.27l-3.87 3.9l-1.35-1.37L15 16.22L17.75 19l5.28-5.32l-1.4-1.41z"
                    fill="currentColor"
                  ></path>
                </svg>
                <div className="flex justify-center flex-col items-center pb-8 pt-4">
                  <p className="uppercase font-bold text-lg flex flex-col items-center gap-1">
                    Abonaste{" "}
                    <p className="text-indigo-600 text-xl">
                      {factura.descripcion + " - " + factura.nombre}
                    </p>
                  </p>
                  <p className="pt-4 uppercase font-bold text-lg flex flex-col items-center gap-1">
                    {" "}
                    por un total de{" "}
                    <p className="text-green-600 text-xl">
                      {formatImporte(factura.monto_abonado)}
                    </p>
                  </p>
                </div>
              </div>
            ) : !hayError && estado_pago === "in_process" ? (
              <div className="bg-white rounded-xl m-4 px-12 flex justify-center flex-col items-center border border-gray-800">
                <svg className="w-16 h-16 text-yellow-500" viewBox="0 0 24 24">
                  <path
                    d="M15 14v-3h3V9l4 3.5l-4 3.5v-2h-3m-1-6.3V9H2V7.7L8 4l6 3.7M7 10h2v5H7v-5m-4 0h2v5H3v-5m10 0v2.5l-2 1.8V10h2m-3.9 6l-.6.5l1.7 1.5H2v-2h7.1m7.9-1v3h-3v2l-4-3.5l4-3.5v2h3z"
                    fill="currentColor"
                  ></path>
                </svg>
                <div className="flex justify-center flex-col items-center pb-8 pt-4">
                  <p className="uppercase font-bold text-lg flex flex-col items-center gap-1">
                    Tu pago está siendo procesado
                  </p>
                  <p className="pt-4 uppercase font-bold text-md justify-center text-center text-indigo-800 flex flex-col items-center gap-1">
                    {" "}
                    Te notificaremos cuando se acredite para que lo veas
                    reflejado
                  </p>
                </div>
              </div>
            ) : (
              <div className="bg-white rounded-xl m-4 px-12 flex justify-center flex-col items-center border border-gray-800">
                <svg className="w-16 h-16 text-red-500" viewBox="0 0 24 24">
                  <path
                    d="M15.46 18.12l1.42 1.42L19 17.41l2.12 2.13l1.42-1.42L20.41 16l2.13-2.12l-1.42-1.42L19 14.59l-2.12-2.13l-1.42 1.42L17.59 16m-2.62-4.38C14.86 10.28 13.58 8.97 12 9c-1.7.04-3 1.3-3 3s1.3 2.94 3 3c.39 0 .77-.08 1.14-.23c.27-1.1.72-2.14 1.83-3.15M13 16H7c0-1.1-.9-2-2-2v-4c1.1 0 2-.9 2-2h10c0 1.1.9 2 2 2v.05c.67.01 1.34.13 2 .35V6H3v12h10.32a6.38 6.38 0 0 1-.32-2z"
                    fill="currentColor"
                  ></path>
                </svg>
                <div className="flex justify-center flex-col items-center pb-8 pt-4">
                  <p className="uppercase font-bold text-lg flex flex-col items-center gap-1">
                    No pudimos verificar tu Pago
                  </p>
                  <div className="text-center mt-2 flex flex-col justify-center">
                    No te preocupes, ponete en contacto con nuestro equipo y
                    brindale esta información:
                    <div className="mt-4 flex justify-center flex-col items-center gap-4">
                      {" "}
                      <div className="flex flex-col justify-center items-center">
                        <p className="font-bold">ID Preferencia:</p>
                        <p className="font-medium">{preference_id}</p>
                      </div>
                      <div className="flex flex-col justify-center items-center">
                        <p className="font-bold">ID Pago:</p>
                        <p className="font-medium">{payment_id}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {(!loadingPago && factura) ||
            (!loadingPago && estado_pago === "in_process") ? (
              <Link
                className="bg-blue-600 text-white px-16 py-2 rounded text-lg mt-4 transition duration-300 hover:bg-indigo-500 font-medium"
                to="/mis-pagos"
              >
                Volver a Mis Pagos
              </Link>
            ) : !loadingPago ? (
              <a
                href={`mailto:sistemas@frontiniyasoc.com.ar?subject=Error Verificacion Pago&body=ID Preferencia:${preference_id} || ID Pago:${payment_id}`}
                className="bg-blue-600 text-white px-16 py-2 rounded text-lg mt-4 transition duration-300 hover:bg-indigo-500 font-medium"
              >
                Contactarse
              </a>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default NotificarPago;
