import { useEffect, useState, useContext } from "react";
import Loading from "../../Loading";
import axiosClient from "../../../config/axios";
import AuthContext from "../../../context/auth/authContext";
import CobrosContext from "../../../context/cobros/cobrosContext";
import UsuariosContext from "../../../context/usuarios/usuariosContext";
import ModalDetalle from "./ModalDetalle";

function MisPagos() {
  const authContext = useContext(AuthContext);
  const { header, userID, getUser, checkGrupo, auth, username } = authContext;
  const cobrosContext = useContext(CobrosContext);
  const { formatDate, formatImporte, calcularVencimiento } = cobrosContext;
  const usuariosContext = useContext(UsuariosContext);
  const { modalDetalle, setModalDetalle } = usuariosContext;
  const [loadingPagos, setLoadingPagos] = useState(true);
  const [pagos, setPagos] = useState([]);
  const [hayError, setHayError] = useState(false);
  const [detalle, setDetalle] = useState([]);
  const [pagosPropios, setPagosPropios] = useState(null);
  const [pagosGrupo, setPagosGrupo] = useState(null);

  useEffect(() => {
    getUser().then(() => {
      if (!checkGrupo("USUARIO")) {
        window.location = "/login";
      } else {
        axiosClient
          .get(`v2/mis_recibos/${userID}`, header)
          .then((response) => {
            var pagosPropiosAux = [];
            var pagosGrupoAux = [];
            response.data.data.map((pago) => {
              if (pago.nroDoc === parseInt(username)) {
                pagosPropiosAux.push(pago);
              } else {
                pagosGrupoAux.push(pago);
              }
            });
            console.log(pagosPropiosAux);
            console.log(pagosGrupoAux);
            setPagosPropios(pagosPropiosAux);
            setPagosGrupo(pagosGrupoAux);
            //setPagos(response.data.data);
            //setLoadingPagos(false);
          })
          .catch((err) => {
            console.log(err);
            setHayError(true);
            setLoadingPagos(false);
          });
      }
    });
  }, []);

  useEffect(() => {
    if (!checkGrupo("USUARIO")) {
      window.location = "/login";
    }
  }, [auth]);

  useEffect(() => {
    if (pagosGrupo !== null && pagosPropios !== null) {
      setLoadingPagos(false);
    }
  }, [pagosGrupo, pagosPropios]);

  if (auth) {
    return (
      <div className="p-4 lg:mt-11 lg:mb-6">
        <div className="grid grid-cols-1 gap-4">
          <div className="p-4 bg-white shadow-lg rounded">
            <div className="lg:flex lg:items-start lg:justify-between mb-2">
              <div>
                <h1 className="text-xl font-bold text-gray-800">Mis Pagos</h1>
              </div>
            </div>
            <div className="mt-0 mb-3">
              <p className="text-sm text-gray-500 ">
                Acá podés revisar el listado de tus pagos realizados
              </p>
            </div>
            {loadingPagos ? <Loading color="mainBlueLight" /> : null}
            {!loadingPagos && pagosPropios && pagosGrupo ? (
              <div>
                <h1 className="text-lg font-medium lg:w-52 text-center py-2 rounded-lg bg-indigo-500 text-white px-4  mt-2 mb-2">
                  Pagos de tus facturas
                </h1>
                {pagosPropios.length > 0 ? (
                  pagosPropios.map((p) => (
                    <div
                      className="border rounded p-3 mb-3 lg:grid lg:grid-cols-8 lg:gap-4"
                      key={p.id}
                    >
                      <div className="lg:border-r lg:m-0 mb-2">
                        <p className="text-sm font-bold text-gray-800">NRO.</p>
                        <p className="text-sm text-gray-500">{p.numero}</p>
                      </div>
                      <div className="lg:border-r lg:m-0 mb-2">
                        <p className="text-sm font-bold text-gray-800">
                          EMPRESA
                        </p>
                        <p className="text-sm text-gray-500">
                          {p.nombre_empresa}
                        </p>
                      </div>
                      <div className="lg:border-r lg:m-0 mb-2">
                        <p className="text-sm font-bold text-gray-800">
                          NOMBRE
                        </p>
                        <p className="text-sm text-gray-500">{p.nombre}</p>
                      </div>
                      <div className="lg:border-r lg:m-0 mb-2">
                        <p className="text-sm font-bold text-gray-800">
                          DESCRIPCIÓN
                        </p>
                        <p className="text-sm text-gray-500">{p.punto_cobro}</p>
                      </div>
                      <div className="lg:border-r lg:m-0 mb-2">
                        <p className="text-sm font-bold text-gray-800">
                          FECHA DE PAGO
                        </p>
                        <p className="text-sm text-gray-500">
                          {formatDate(p.fecha) + " - " + p.hora + " hs"}
                        </p>
                      </div>
                      <div className="lg:border-r lg:m-0 mb-2">
                        <p className="text-sm font-bold text-gray-800">
                          FORMA DE PAGO
                        </p>
                        <p className="text-sm text-gray-500">{p.formaPago}</p>
                      </div>
                      <div className="lg:border-r lg:m-0 mb-2">
                        <p className="text-sm font-bold text-gray-800">
                          IMPORTE ABONADO
                        </p>
                        <p
                          className={
                            calcularVencimiento(p.fechaVencimiento1)
                              ? "text-sm text-red-500"
                              : "text-sm text-gray-500"
                          }
                        >
                          {calcularVencimiento(p.fechaVencimiento1)
                            ? formatImporte(
                                (
                                  parseFloat(p.importe) + parseFloat(p.importe2)
                                ).toString()
                              )
                            : formatImporte(p.importe)}
                        </p>
                      </div>
                      <div className="lg:m-0 mb-2 flex justify-center">
                        <button
                          className="bg-mainBlueLight text-white font-semibold rounded  focus:outline-none py-1 transition duration-300 hover:bg-indigo-500 w-full flex flex-row justify-center items-center gap-2"
                          onClick={() => {
                            setDetalle(p.facturas);
                            setModalDetalle(true);
                          }}
                        >
                          <svg
                            aria-hidden="true"
                            role="img"
                            className="iconify iconify--tabler h-7 w-7"
                            preserveAspectRatio="xMidYMid meet"
                            viewBox="0 0 24 24"
                          >
                            <g
                              className="icon-tabler"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            >
                              <path d="M5 21V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v16l-3-2l-2 2l-2-2l-2 2l-2-2l-3 2"></path>
                              <path d="M14 8h-2.5a1.5 1.5 0 0 0 0 3h1a1.5 1.5 0 0 1 0 3H10m2 0v1.5m0-9V8"></path>
                            </g>
                          </svg>
                          Ver Detalle
                        </button>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="p-4 mt-4 mb-4 bg-indigo-200 rounded text-center font-bold text-gray-600 border-2 border-indigo-600 text-xl">
                    {" "}
                    Aún no realizaste ningún pago de tus facturas{" "}
                  </div>
                )}
                <h1 className="text-lg font-medium lg:w-64 text-center py-2 rounded-lg bg-indigo-500 text-white px-4  mt-2 mb-2">
                  Pagos de tu Grupo de Pago
                </h1>
                {pagosGrupo.length > 0 ? (
                  pagosGrupo.map((p) => (
                    <div
                      className="border rounded p-3 mb-3 lg:grid lg:grid-cols-8 lg:gap-4"
                      key={p.id}
                    >
                      <div className="lg:border-r lg:m-0 mb-2">
                        <p className="text-sm font-bold text-gray-800">NRO.</p>
                        <p className="text-sm text-gray-500">{p.numero}</p>
                      </div>
                      <div className="lg:border-r lg:m-0 mb-2">
                        <p className="text-sm font-bold text-gray-800">
                          EMPRESA
                        </p>
                        <p className="text-sm text-gray-500">
                          {p.nombre_empresa}
                        </p>
                      </div>
                      <div className="lg:border-r lg:m-0 mb-2">
                        <p className="text-sm font-bold text-gray-800">
                          NOMBRE
                        </p>
                        <p className="text-sm text-gray-500">{p.nombre}</p>
                      </div>
                      <div className="lg:border-r lg:m-0 mb-2">
                        <p className="text-sm font-bold text-gray-800">
                          DESCRIPCIÓN
                        </p>
                        <p className="text-sm text-gray-500">{p.punto_cobro}</p>
                      </div>
                      <div className="lg:border-r lg:m-0 mb-2">
                        <p className="text-sm font-bold text-gray-800">
                          FECHA DE PAGO
                        </p>
                        <p className="text-sm text-gray-500">
                          {formatDate(p.fecha) + " - " + p.hora + " hs"}
                        </p>
                      </div>
                      <div className="lg:border-r lg:m-0 mb-2">
                        <p className="text-sm font-bold text-gray-800">
                          FORMA DE PAGO
                        </p>
                        <p className="text-sm text-gray-500">{p.formaPago}</p>
                      </div>
                      <div className="lg:border-r lg:m-0 mb-2">
                        <p className="text-sm font-bold text-gray-800">
                          IMPORTE ABONADO
                        </p>
                        <p
                          className={
                            calcularVencimiento(p.fechaVencimiento1)
                              ? "text-sm text-red-500"
                              : "text-sm text-gray-500"
                          }
                        >
                          {calcularVencimiento(p.fechaVencimiento1)
                            ? formatImporte(
                                (
                                  parseFloat(p.importe) + parseFloat(p.importe2)
                                ).toString()
                              )
                            : formatImporte(p.importe)}
                        </p>
                      </div>
                      <div className="lg:m-0 mb-2 flex justify-center">
                        <button
                          className="bg-mainBlueLight text-white font-semibold rounded  focus:outline-none py-1 transition duration-300 hover:bg-indigo-500 w-full flex flex-row justify-center items-center gap-2"
                          onClick={() => {
                            console.log(p);
                            setDetalle(p.facturas);
                            setModalDetalle(true);
                          }}
                        >
                          <svg
                            aria-hidden="true"
                            role="img"
                            className="iconify iconify--tabler h-7 w-7"
                            preserveAspectRatio="xMidYMid meet"
                            viewBox="0 0 24 24"
                          >
                            <g
                              className="icon-tabler"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            >
                              <path d="M5 21V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v16l-3-2l-2 2l-2-2l-2 2l-2-2l-3 2"></path>
                              <path d="M14 8h-2.5a1.5 1.5 0 0 0 0 3h1a1.5 1.5 0 0 1 0 3H10m2 0v1.5m0-9V8"></path>
                            </g>
                          </svg>
                          Ver Detalle
                        </button>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="p-4 mt-4 mb-4 bg-indigo-200 rounded text-center font-bold text-gray-600 border-2 border-indigo-600 text-xl">
                    {" "}
                    Aún no realizaste ningún pago de las facturas de tu Grupo de
                    Pago{" "}
                  </div>
                )}
              </div>
            ) : !loadingPagos && !hayError ? (
              <div className="flex justify-center">
                <h1 className="text-xl bg-indigo-500 py-2 px-2 font-medium rounded lg:w-3/4 2xl:w-1/2 text-white items-center text-center mt-6 mb-6 flex flex-col lg:flex-row lg:gap-2 justify-center">
                  <p className="text-2xl">💵</p> Aún no tenés pagos registrados.
                  Cuando pagues, van a aparecer acá.
                </h1>
              </div>
            ) : !loadingPagos && hayError ? (
              <div className="flex justify-center">
                <p className="text-xl bg-red-400 py-2 px-2 font-medium rounded lg:w-3/4 2xl:w-1/2 text-white text-center mt-6 mb-6 flex flex-col lg:flex-row lg:gap-2 justify-center">
                  <p className="text-2xl lg:text-xl">🙁</p> ¡Ups! Hubo un error
                  recuperando tus pagos. Por favor, intentá más tarde.{" "}
                </p>
              </div>
            ) : null}
          </div>
        </div>
        {modalDetalle ? <ModalDetalle data={detalle} /> : null}
      </div>
    );
  } else {
    window.location = "/";
  }
}

export default MisPagos;
