import React, { useEffect, useState, useContext } from "react";
import cardinalLogo from "../assets/cardinal-full-white.svg";
import frontiniLogo from "../assets/frontini-logo.svg";
import bannerFoot from "../assets/bannerfooter.gif";
import AuthContext from "../context/auth/authContext";


function Footer() {
  const [showFooter, setShowFooter] = useState(false);
  const authContext = useContext(AuthContext);
  const { auth } = authContext;
  console.log(authContext);
  const checkURL = () => {
    setShowFooter(
      window.location.pathname !== "/factura" &&
        !window.location.pathname.includes("factura-descarga")
    );
  };

  useEffect(() => {
    checkURL();
  }, []);

  if (showFooter) {
    return (
      <div className="">
        <div className=" bg-mainBlue p-10">
          <div className="xl:flex xl:justify-around justify-items-center">
            <div className="mb-6">
              <img src={cardinalLogo} className="h-14" alt="Cardinal logo" />
            </div>
            {
              auth ?
                <div classsName="xl:flex xl:justify-between grid justify-items-center">
                  <img src={bannerFoot} className="h-32 w-full "></img>
                </div>
              : null
            }            
            <div className="mb-4">
              <img
                src={frontiniLogo}
                className="h-14 mb-4"
                alt="Cardinal logo"
              />
              <p className="text-white font-bold text-sm">Frontini S.R.L</p>
              <p className="text-white text-sm">
                Dirección Calle 14 N° 622 1/2
              </p>
              <p className="text-white text-sm">
                La Plata, Bs. As. – Argentina
              </p>
            </div>
          </div>
        </div>
        <div className="bg-mainBlueLight text-center p-4">
          <a
            className="text-gray-300 font-bold"
            href="https://frontiniyasoc.com.ar/"
            target="_blank"
          >
            Desarrollado por Frontini Soluciones Informáticas
          </a>
        </div>
      </div>
    );
  } else {
    return <div></div>;
  }
}

export default Footer;
