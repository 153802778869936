import { useState, useContext } from "react";
import DatosPersonales from "./MisDatos/DatosPersonales";
import GrupoPago from "./MisDatos/GrupoPago/GrupoPago";
import MisArchivos from "./MisArchivos";
import AuthContext from "../../context/auth/authContext";

function MisDatos() {
  const [elegido, setElegido] = useState("personales");
  const authContext = useContext(AuthContext);
  const { auth } = authContext;

  if (auth) {
    return (
      <div className="p-4">
        <div className="lg:grid lg:grid-cols-5 lg:gap-4">
          <div className="bg-white rounded flex flex-col gap-4 p-4 shadow-xl lg:h-52">
            <button
              className={
                elegido === "personales"
                  ? "rounded bg-mainBlueLight focus:outline-none text-white font-medium text-left px-4 py-1"
                  : "rounded bg-gray-200 py-1 focus:outline-none text-gray-800 font-medium text-left px-4 "
              }
              onClick={() => setElegido("personales")}
            >
              Datos Personales
            </button>
            {/*           <button
              className={
                elegido === "facturacion"A
                  ? "rounded bg-mainBlueLight focus:outline-none text-white font-medium text-left px-4 py-1"
                  : "rounded bg-gray-200 py-1 focus:outline-none text-gray-800 font-medium text-left px-4 "
              }
              onClick={() => setElegido("facturacion")}
            >
              Datos de Facturación
            </button> */}
            <button
              className={
                elegido === "grupo-pago"
                  ? "rounded bg-mainBlueLight focus:outline-none text-white font-medium text-left px-4 py-1"
                  : "rounded bg-gray-200 py-1 focus:outline-none text-gray-700 font-medium text-left px-4"
              }
              onClick={() => setElegido("grupo-pago")}
            >
              <div className="flex flex-row items-center">
                <p className="mr-1">Grupo de Pago</p>
              </div>
            </button>
            <button
              className={
                elegido === "datos-servicio"
                  ? " rounded bg-mainBlueLight focus:outline-none text-white font-medium text-left px-4 py-1"
                  : "rounded bg-gray-200 py-1 focus:outline-none text-gray-800 font-medium text-left px-4 "
              }
              onClick={() => setElegido("datos-servicio")}
            >
              Credenciales
            </button>
          </div>
          <div className="col-span-4">
            {elegido === "personales" ? <DatosPersonales /> : null}
            {/*  {elegido === "facturacion" ? <DatosFacturacion /> : null} */}
            {elegido === "grupo-pago" ? <GrupoPago /> : null}
            {elegido === "datos-servicio" ? <MisArchivos /> : null}
          </div>
        </div>
      </div>
    );
  } else {
    window.location = "/";
  }
}

export default MisDatos;
