import React, { useReducer, useContext, useEffect, useState } from "react";

import cobrosContext from "./cobrosContext";
import cobrosReducer from "./cobrosReducer";

import AuthContext from "../auth/authContext";
import AlertContext from "../alerts/alertContext";

import axiosClient from "../../config/axios";

import jsPDF from "jspdf";

import {
  RESULTADO_PERSONA,
  FACTURAS_SELECCIONADAS,
  SELECCIONAR_FACTURA,
  IMPORTE_TOTAL,
  VERIFICAR_PARCIAL,
  SET_PAGO_PARCIAL,
  FACTURAS_CLIENTE,
  NOMBRE_CLIENTE,
  SET_IMPORTE_CLIENTE,
  RECIBOS_CLIENTE,
  SET_BUSCADOR_ESTADO,
  VENCIMIENTO_CLIENTE,
  TOTAL_CLIENTE,
  RESET_STATE,
  DNI_PERSONA,
} from "../../types/index.js";

const CobrosState = (props) => {
  const authContext = useContext(AuthContext);
  const { name, userID, fecha, hora, header } = authContext;

  const alertContext = useContext(AlertContext);
  const { setAlert } = alertContext;

  const initialState = {
    resultadoPersona: [],
    facturasCliente: [],
    nombreCliente: "",
    facturaSeleccionada: {},
    seleccionadasPagar: [],
    importeTotal: 0,
    importeCliente: 0,
    pagoParcial: "",
    pagoVerificado: false,
    recibosIDs: [],
    recibosCliente: [],
    buscadorActivo: true,
    montoPorCliente: [],
    vencimientoCliente: 0,
    totalCliente: 0,
    dniPersona: null,
  };

  const [state, dispatch] = useReducer(cobrosReducer, initialState);

  const [pagoCobrador, setPagoCobrador] = useState({});
  const [vuelto, setVuelto] = useState(false);
  const [observaciones, setObservaciones] = useState(false);
  const [dataObservaciones, setDataObservaciones] = useState("");
  const [loadingState, setLoadingState] = useState(false);
  const [metodoPago, setMetodoPago] = useState("");
  const [modalAnularRecibo, setModalAnularRecibo] = useState(false);
  const [idRecibo, setIdRecibo] = useState(null);
  const [recibosUpdated, setRecibosUpdated] = useState(false);
  const [datosFactura, setDatosFactura] = useState({});

  useEffect(() => {
    //valido si hay facturas con un estado erroneo para habilitar/deshabilitar el boton de pago
    validacionFacturasParciales();
  }, [state.seleccionadasPagar, state.recibosCliente]);

  useEffect(() => {
    selectPersona(state.dniPersona);
  }, [fecha]);

  //Limpia el state inicial cuando se cambia de componente.
  const resetState = () => {
    dispatch({
      type: RESET_STATE,
    });
  };

  //busca la persona por DNI
  const getPersona = async (data) => {
    setLoadingState(true);
    await axiosClient
      .post(`/getPersonas`, data, header)
      .then((response) => {
        dispatch({
          type: RESULTADO_PERSONA,
          payload: response.data.data,
        });
        dispatch({
          type: DNI_PERSONA,
          payload: data,
        });
        dispatch({
          type: FACTURAS_CLIENTE,
          payload: [],
        });
        setLoadingState(false);
      })
      .catch((err) => {
        setLoadingState(false);
      });
  };

  //Busca la persona para traer las facturas correspondientes.
  const selectPersona = async (data) => {
    setLoadingState(true);
    dispatch({
      type: RESULTADO_PERSONA,
      payload: [],
    });
    dispatch({
      type: DNI_PERSONA,
      payload: data,
    });
    await axiosClient
      .post(
        `v2/facturas_cliente`,
        {
          dniOsocio: data,
          cobrador: userID,
          fecha_de_cobro: fecha,
        },
        header
      )
      .then((response) => {
        setLoadingState(false);
        //Envio la data a los states para poder manejar las facturas.
        dispatch({
          type: FACTURAS_CLIENTE,
          payload: response.data.data.facturas,
        });
        dispatch({
          type: VENCIMIENTO_CLIENTE,
          payload: response.data.data.total_deuda,
        });
        dispatch({
          type: TOTAL_CLIENTE,
          payload: response.data.data.total,
        });

        //Filtro facturas antes de volverlas al array por si ya habían selecciondas del mismo usuario.
        filtrarFacturasSeleccionadas();
        dispatch({
          type: NOMBRE_CLIENTE,
          payload: response.data.data.usuario,
        });

        //Si el data viene vacio muestro un alert que no hay facturas disponibles.
        if (response.data.data.facturas.length === 0) {
          setAlert("El usuario no registra servicios a pagar", "yellow-300");
        }
      })
      .catch((err) => {
        setLoadingState(false);
      });
  };

  //Filtra las facturas ya seleccionadas de un cliente si se busca otra vez.
  //Esta funcion se ejecuta en el componente "Facturas Cliente"
  const filtrarFacturasSeleccionadas = () => {
    if (state.facturasCliente.length > 0) {
      let filtrar = state.facturasCliente.filter(
        (item) =>
          !state.seleccionadasPagar.some((other) => item.id === other.id)
      );
      dispatch({
        type: FACTURAS_CLIENTE,
        payload: filtrar,
      });
    }
  };

  //Agrega la factura seleccionada al state y calcula el importe.
  const addFactura = (data) => {
    dispatch({
      type: SELECCIONAR_FACTURA,
      payload: data,
    });
    dispatch({
      type: IMPORTE_TOTAL,
      payload: state.importeTotal + parseInt(data.saldo) + data.interes,
    });
  };

  //Filtra de la lista las facturas que se seleccionan para pagar.
  const filtrarFacturas = () => {
    if (state.facturasCliente.length > 0) {
      let filtrar = state.facturasCliente.filter(
        (f) => f.id !== state.facturaSeleccionada.id
      );
      dispatch({
        type: FACTURAS_CLIENTE,
        payload: filtrar,
      });
      dispatch({
        type: FACTURAS_SELECCIONADAS,
        payload: [...state.seleccionadasPagar, state.facturaSeleccionada],
      });
    }
  };

  //Eliminar Facturas seleccionadas
  const eliminarFacturasSelecionadas = () => {
    dispatch({
      type: FACTURAS_SELECCIONADAS,
      payload: [],
    });
    dispatch({
      type: IMPORTE_TOTAL,
      payload: 0,
    });
  };

  //Eliminar Factura seleccionada especifica
  const eliminarFactura = (factura) => {
    let filtrar = state.seleccionadasPagar.filter((f) => f.id !== factura.id);
    dispatch({
      type: FACTURAS_SELECCIONADAS,
      payload: filtrar,
    });
    dispatch({
      type: IMPORTE_TOTAL,
      payload: state.importeTotal - factura.saldo - factura.interes,
    });
  };

  //Formatea las fechas segun lo necesario en las vistas.
  const formatDate = (data) => {
    var date = new Date(data);

    if (!isNaN(date.getTime())) {
      var day = date.getUTCDate().toString();
      var month = (date.getUTCMonth() + 1).toString();
      var fecha =
        (day[1] ? day : "0" + day[0]) +
        "/" +
        (month[1] ? month : "0" + month[0]) +
        "/" +
        date.getFullYear();
      return fecha;
    }
  };

  // Funcion para formatear el importe
  const formatImporte = (data) => {
    const total = new Intl.NumberFormat("de-DE", {
      minimumFractionDigits: 2,
    }).format(data);

    return "$ " + total;
  };

  //Funcion para comparar el vencimiento de una fecha.
  const calcularVencimiento = (fechaVencimiento) => {
    let fecha = new Date();
    let vencimiento = new Date(fechaVencimiento);
    if (fecha > vencimiento) {
      return true;
    } else {
      return false;
    }
  };

  const setPagoParcial = (data) => {
    dispatch({
      type: SET_PAGO_PARCIAL,
      payload: data,
    });
  };

  const setPagoVerificado = (data) => {
    dispatch({
      type: VERIFICAR_PARCIAL,
      payload: data,
    });
  };

  const validacionFacturasParciales = () => {
    //busco en las facturas seleccionadas las que tengan el estado de error
    var result = state.seleccionadasPagar.filter((obj) => {
      return obj.estado === "No Pagada a tiempo";
    });

    //Activo o desactivo el error, depende si hay coincidencias en el filtro de arriba.
    if (result.length > 0) {
      dispatch({
        type: VERIFICAR_PARCIAL,
        payload: false,
      });
    } else {
      dispatch({
        type: VERIFICAR_PARCIAL,
        payload: true,
      });
    }
  };

  const setImporteCliente = (data) => {
    dispatch({
      type: SET_IMPORTE_CLIENTE,
      payload: data,
    });
  };

  //Procesa el pago parcial para chequear las facturas seleccionadas del usuario.
  const procesarPagoParcial = async (data) => {
    setLoadingState(true);
    dispatch({
      type: VERIFICAR_PARCIAL,
      payload: false,
    });

    await axiosClient
      .post(
        `v2/facturas_recibo/provisional`,
        {
          facturas: data,
          importeRecibo: state.pagoParcial,
          fecha_de_cobro: fecha,
        },
        header
      )
      .then((response) => {
        setLoadingState(false);
        //Reemplaza las facturas seleccionadas asignando los estados correspondientes.
        dispatch({
          type: FACTURAS_SELECCIONADAS,
          payload: response.data.data,
        });
        validacionFacturasParciales();
        dispatch({
          type: IMPORTE_TOTAL,
          payload: parseInt(state.pagoParcial),
        });
      })
      .catch((err) => {
        setLoadingState(false);
        setAlert(err.response.data.message, "red-300");
      });
  };

  //Funcion para cambiar la vista segun el estado de las facturas (pago parcial)
  const checkEstadoFactura = (data) => {
    switch (data) {
      case "Pagada completa a tiempo":
        return (
          <span className="bg-green-400 rounded px-4 py-1 text-white">
            {data}
          </span>
        );
      case "Pagada completa vencida":
        return (
          <span className="bg-green-400 rounded px-4 py-1 text-white">
            {data}
          </span>
        );
      case "Pagada incompleta a tiempo":
        return (
          <span className="bg-yellow-400 rounded px-4 py-1 text-white">
            {data}
          </span>
        );
      case "Pagada incompleta vencida":
        return (
          <span className="bg-yellow-400 rounded px-4 py-1 text-white">
            {data}
          </span>
        );
      case "No Pagada a tiempo":
        return (
          <span className="bg-red-500 rounded px-4 py-1 text-white">
            {data}
          </span>
        );
      case "No Pagada vencida":
        return (
          <span className="bg-red-500 rounded px-4 py-1 text-white">
            {data}
          </span>
        );
      default:
        return <span></span>;
    }
  };

  //Función para procesar el pago y traer el ID de los recibos.
  const procesarPago = async () => {
    setLoadingState(true);
    dispatch({
      type: SET_PAGO_PARCIAL,
      payload: 0,
    });

    await axiosClient
      .post(
        `v2/nuevo_recibo`,
        {
          facturas: state.seleccionadasPagar,
          importeRecibo: state.importeTotal,
          fecha_de_cobro: fecha,
          observacionesRecibo: dataObservaciones,
          idCobrador: userID,
          horaDeCobro: hora,
          formaPago: metodoPago,
        },
        header
      )
      .then((response) => {
        setLoadingState(false);
        mostrarRecibos(response.data.data.recibos);
        dispatch({
          type: FACTURAS_SELECCIONADAS,
          payload: [],
        });
        dispatch({
          type: FACTURAS_CLIENTE,
          payload: [],
        });
        dispatch({
          type: SET_BUSCADOR_ESTADO,
          payload: false,
        });
      })
      .catch((err) => {
        setAlert(err.response.data.message, "red-300");
        setLoadingState(false);
      });
  };

  //Función para mostrar los recibos procesados.
  const mostrarRecibos = async (data) => {
    setLoadingState(true);
    await axiosClient
      .post(
        `v2/recibos`,
        {
          recibos: data,
        },

        header
      )
      .then((response) => {
        setLoadingState(false);
        dispatch({
          type: RECIBOS_CLIENTE,
          payload: response.data.data,
        });

        dispatch({
          type: IMPORTE_TOTAL,
          payload: 0,
        });
      })
      .catch((err) => {
        setLoadingState(false);
      });
  };

  //Función para consultar e imprimir algun recibo específico
  const consultarImprimirRecibo = async (data) => {
    await axiosClient
      .post(
        `v2/recibos`,
        {
          recibos: data,
        },

        header
      )
      .then((response) => {
        imprimirTicket(response.data.data[0], true);
      })
      .catch((err) => {});
  };

  //Función para consultar e imprimir un cierre de caja con detalle
  const consultarImprimirCierreDetalle = async (data) => {
    await axiosClient
      .get(`v2/rendiciones/${data}`, header)
      .then((response) => {
        imprimirCierreDetalle(response.data.data.rendicion);
      })
      .catch((err) => {});
  };

  //Función para consultar e imprimir un cierre de caja con detalle
  const consultarImprimirCierre = async (data) => {
    await axiosClient
      .get(`v2/rendiciones/${data}`, header)
      .then((response) => {
        imprimirCierre(response.data.data.rendicion);
      })
      .catch((err) => {});
  };

  //Funcion que vacía los recibos del cliente, para poder activar la interfaz de cobros nuevamente
  const pagoTerminado = () => {
    dispatch({
      type: RECIBOS_CLIENTE,
      payload: [],
    });
    dispatch({
      type: SET_BUSCADOR_ESTADO,
      payload: true,
    });
  };

  //diseño de los tickets para pasar a las funciones de impresión.
  const dataTicket = (doc, data, reimpresion) => {
    //Si no es reimpresion imprime con la fecha del dia de emision, sino, caputra la fecha del pago del recibo.
    if (!reimpresion) {
      let fechaTicket = new Date(fecha);
      let fechaLetras = {
        year: "numeric",
        month: "long",
        day: "numeric",
        timeZone: "UTC",
      };

      doc.addFont("courier", "courier", "normal");

      //Debug para saber las fuentes disponibles en el sistema.
      //console.log(doc.getFontList());

      doc.setFont("courier", "bold");
      doc.setFontSize(12);
      doc.text(2, 5, "Cardinal Puntos de Pago");
      doc.setFont("courier", "italic");
      doc.setFontSize(9);
      doc.text(2, 10, "Comprobante de Pago");

      doc.setFont("courier", "bold");
      doc.setFontSize(8);
      doc.text(
        2,
        20,
        "Dolores, " +
          fechaTicket.toLocaleDateString("es-ES", fechaLetras) +
          " " +
          hora +
          "hs"
      );

      doc.setFont("courier", "normal");
      doc.setFontSize(9);
      doc.text(2, 30, "Recibimos de " + data.nombre);
      doc.setFont("courier", "bold");
      doc.text(2, 35, "El monto de " + formatImporte(data.importe));
      doc.setFont("courier", "normal");
      doc.text(2, 40, "Para la empresa " + data.empresa.nombre);
      doc.text(2, 45, "Cobrador: " + name);
      doc.text(2, 50, "Concepto de pago:");

      if (data.facturas.length > 0) {
        let linea = 50;
        doc.setFontSize(7);
        doc.setFont("courier", "bold");
        for (var i = 0; i < data.facturas.length; i++) {
          linea += 5;
          doc.text(
            2,
            linea,
            data.facturas[i].concepto +
              (data.facturas[i].pagoTotal ? " - COMPLETO" : " - PARCIAL")
          );
        }
      }
    } else {
      let fechaTicket = new Date(data.fecha);
      let fechaLetras = {
        year: "numeric",
        month: "long",
        day: "numeric",
        timeZone: "UTC",
      };

      doc.addFont("courier", "courier", "normal");

      //Debug para saber las fuentes disponibles en el sistema.
      //console.log(doc.getFontList());

      doc.setFont("courier", "bold");
      doc.setFontSize(12);
      doc.text(2, 5, "Cardinal Puntos de Pago");

      doc.setFont("courier", "italic");
      doc.setFontSize(9);
      doc.text(2, 10, "Comprobante de Pago");

      doc.setFont("courier", "bold");
      doc.setFontSize(8);
      doc.text(
        6,
        20,
        "Dolores, " +
          fechaTicket.toLocaleDateString("es-AR", fechaLetras) +
          " " +
          data.hora +
          "hs"
      );

      doc.setFont("courier", "normal");
      doc.setFontSize(9);
      doc.text(2, 30, "Recibimos de " + data.nombre);
      doc.setFont("courier", "bold");
      doc.text(2, 35, "El monto de " + formatImporte(data.importe));
      doc.setFont("courier", "normal");
      doc.text(2, 40, "Para la empresa " + data.empresa.nombre);
      doc.text(2, 45, "Cobrador: " + name);
      doc.text(2, 50, "Concepto de pago:");

      if (data.facturas.length > 0) {
        let linea = 50;
        doc.setFontSize(7);
        doc.setFont("courier", "bold");
        for (var i = 0; i < data.facturas.length; i++) {
          linea += 5;
          doc.text(
            2,
            linea,
            data.facturas[i].concepto +
              (data.facturas[i].pagoTotal ? " - COMPLETO" : " - PARCIAL")
          );
        }
      }
    }
  };

  //imprime el ticket individualmente
  const imprimirTicket = (recibo, reimpresion) => {
    const doc = new jsPDF({
      orientation: "portrait",
      unit: "mm",
      format: [190, 700],
    });

    dataTicket(doc, recibo, reimpresion);
    doc.autoPrint();
    doc.output("dataurlnewwindow", { filename: "Comprobante-pago.pdf" });
  };

  //imprime la rendición con todos los recibos.
  const imprimirCierreDetalle = (rendicion) => {
    const doc = new jsPDF({
      orientation: "portrait",
      unit: "mm",
      format: [190, 700],
    });

    let fechaCierre = new Date(rendicion.fecha);
    let fechaLetras = {
      year: "numeric",
      month: "long",
      day: "numeric",
      timeZone: "UTC",
    };

    doc.addFont("courier", "courier", "normal");

    //Debug para saber las fuentes disponibles en el sistema.
    //console.log(doc.getFontList());

    doc.setFont("courier", "bold");
    doc.setFontSize(12);
    doc.text(2, 5, "Cardinal Puntos de Pago");

    doc.setFont("courier", "italic");
    doc.setFontSize(9);
    doc.text(2, 10, "Cierre de Caja");

    doc.setFont("courier", "bold");
    doc.setFontSize(8);
    doc.text(
      2,
      20,
      fechaCierre.toLocaleDateString("es-AR", fechaLetras) +
        " " +
        rendicion.hora +
        "hs"
    );

    doc.setFont("courier", "normal");
    doc.setFontSize(9);
    doc.text(2, 30, "Cobrador: " + rendicion.cobrador.nombre);
    doc.setFont("courier", "bold");
    doc.text(2, 35, "El monto de " + formatImporte(rendicion.importe));
    doc.setFont("courier", "normal");
    doc.text(2, 40, "Cierre de Caja Numero: " + rendicion.id);
    doc.text(2, 45, "Observaciones: ");
    doc.text(2, 50, rendicion.observaciones);
    doc.setFont("courier", "bold");
    doc.text(2, 60, "Recibos:");

    if (rendicion.recibos.length > 0) {
      let linea = 60;
      doc.setFont("courier", "bold");
      doc.setFontSize(7);
      for (var i = 0; i < rendicion.recibos.length; i++) {
        linea += 5;
        doc.text(
          2,
          linea,
          rendicion.recibos[i].nombre + " - " + rendicion.recibos[i].fecha
        );
        doc.text(59, linea, formatImporte(rendicion.recibos[i].importe));
      }
    }

    doc.autoPrint();
    doc.output("dataurlnewwindow", {
      filename: `Cierre-de-Caja-${rendicion.id}.pdf`,
    });
  };

  //imprime la rendición sin los recibos.
  const imprimirCierre = (rendicion) => {
    const doc = new jsPDF({
      orientation: "portrait",
      unit: "mm",
      format: [190, 700],
    });

    let fechaCierre = new Date(rendicion.fecha);
    let fechaLetras = {
      year: "numeric",
      month: "long",
      day: "numeric",
      timeZone: "UTC",
    };

    doc.addFont("courier", "courier", "normal");

    //Debug para saber las fuentes disponibles en el sistema.
    //console.log(doc.getFontList());

    doc.setFont("courier", "bold");
    doc.setFontSize(12);
    doc.text(2, 5, "Cardinal Puntos de Pago");

    doc.setFont("courier", "italic");
    doc.setFontSize(9);
    doc.text(2, 10, "Cierre de Caja");

    doc.setFont("courier", "bold");
    doc.setFontSize(8);
    doc.text(
      2,
      20,
      fechaCierre.toLocaleDateString("es-AR", fechaLetras) +
        " " +
        rendicion.hora +
        "hs"
    );

    doc.setFont("courier", "normal");
    doc.setFontSize(9);
    doc.text(2, 30, "Cobrador: " + rendicion.cobrador.nombre);
    doc.setFont("courier", "bold");
    doc.text(2, 35, "El monto de " + formatImporte(rendicion.importe));
    doc.setFont("courier", "normal");
    doc.text(2, 40, "Cierre de Caja Numero: " + rendicion.id);
    doc.text(2, 45, "Observaciones: ");
    doc.text(2, 50, rendicion.observaciones);

    doc.autoPrint();
    doc.output("dataurlnewwindow", {
      filename: `Cierre-de-Caja-${rendicion.id}.pdf`,
    });
  };

  //Manda a imprimir todos los tickets de la lista de recibos del cliente.
  const imprimirTodosTickets = () => {
    const doc = new jsPDF({
      orientation: "portrait",
      unit: "mm",
      format: [190, 700],
    });

    for (var i = 0; i < state.recibosCliente.length; i++) {
      var recibo = state.recibosCliente[i];
      dataTicket(doc, recibo);
      doc.addPage();
    }

    doc.autoPrint();
    doc.output("dataurlnewwindow", { filename: "Comprobante-pago.pdf" });
  };

  //Habilita o no el calculo de vuelto según el método de pago seleccionado.
  const setMostrarVuelto = () => {
    if (pagoCobrador.length > 0) {
      if (pagoCobrador[0].vuelto === 1) {
        setVuelto(true);
      } else {
        setVuelto(false);
      }
    }
  };

  // Habilita o no el campo para agregar observaciones al recibo.
  const setMostrarObservaciones = () => {
    if (pagoCobrador.length > 0) {
      if (pagoCobrador[0].pideObservaciones === 1) {
        setObservaciones(true);
      } else {
        setObservaciones(false);
      }
    }
  };

  return (
    <cobrosContext.Provider
      value={{
        formatDate,
        formatImporte,
        calcularVencimiento,
        checkEstadoFactura,
        selectPersona,
        getPersona,
        filtrarFacturasSeleccionadas,
        addFactura,
        filtrarFacturas,
        eliminarFactura,
        eliminarFacturasSelecionadas,
        procesarPagoParcial,
        setPagoParcial,
        setPagoVerificado,
        setImporteCliente,
        procesarPago,
        pagoTerminado,
        imprimirTicket,
        imprimirTodosTickets,
        resultadoPersona: state.resultadoPersona,
        facturasCliente: state.facturasCliente,
        seleccionadasPagar: state.seleccionadasPagar,
        nombreCliente: state.nombreCliente,
        importeTotal: state.importeTotal,
        importeCliente: state.importeCliente,
        pagoParcial: state.pagoParcial,
        pagoVerificado: state.pagoVerificado,
        facturaSeleccionada: state.facturaSeleccionada,
        recibosCliente: state.recibosCliente,
        buscadorActivo: state.buscadorActivo,
        vencimientoCliente: state.vencimientoCliente,
        dniPersona: state.dniPersona,
        pagoCobrador: pagoCobrador,
        setPagoCobrador,
        vuelto: vuelto,
        setMostrarVuelto,
        observaciones: observaciones,
        setMostrarObservaciones,
        dataObservaciones: dataObservaciones,
        setDataObservaciones,
        resetState,
        consultarImprimirRecibo,
        consultarImprimirCierreDetalle,
        consultarImprimirCierre,
        setMetodoPago,
        loadingState: loadingState,
        metodoPago,
        totalCliente: state.totalCliente,
        modalAnularRecibo,
        setModalAnularRecibo,
        idRecibo,
        setIdRecibo,
        recibosUpdated,
        setRecibosUpdated,
        datosFactura,
        setDatosFactura,
      }}
    >
      {props.children}
    </cobrosContext.Provider>
  );
};

export default CobrosState;
