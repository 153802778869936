import React, { useEffect, useContext, useState, useRef } from "react";
import AuthContext from "../../context/auth/authContext";
import CobrosContext from "../../context/cobros/cobrosContext";
import ReCAPTCHA from "react-google-recaptcha";
import axiosClient from "../../config/axios";
import { useHistory, Link, useLocation } from "react-router-dom";
import publicIp from "public-ip";
import bannerAside from "../../assets/banner.gif";

function UsuarioData() {
  let location = useLocation();
  const idFactura = new URLSearchParams(location.search).get("f");

  const idEmpresa = new URLSearchParams(location.search).get("idEmpresa");
  const nroDoc = new URLSearchParams(location.search).get("nroDoc");
  const nroSoc = new URLSearchParams(location.search).get("nroSoc");
 /*  if (nroDoc === null || nroSoc === "") {
    window.location.href = "/login";
  }  */
  const history = useHistory();
  const authContext = useContext(AuthContext);
  const { getUser, auth } = authContext;

  const cobrosContext = useContext(CobrosContext);
  const {
    formatDate,
    formatImporte,
    calcularVencimiento,
    addFactura,
    setDatosFactura,
  } = cobrosContext;

  const recaptchaRef = useRef();

  const [captchaVerified, setCaptchaVerified] = useState(false);
  const [facturasCliente, setFacturasCliente] = useState([]);
  const [archivosUsuario, setArchivosUsuario] = useState([]);
  const [deudaTotal, setDeudaTotal] = useState(0);
  const [deudaVencida, setDeudaVencida] = useState(0);
  const [mainLoading, setMainLoading] = useState(true);
  const [loadingLogin, setLoadingLogin] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loadingFactura, setLoadingFactura] = useState({});
  const [datosEmpresa, setDatosEmpresa] = useState({});
  const [showAlert, setShowAlert] = useState(false);
  const [datosAlert, setDatosAlert] = useState({
    type: "warning",
    color: "yellow",
    bgColor: "300",
    accentColor: "400",
    message: "Warning!",
  });

  const [datosUsuario, setDatosUsuario] = useState({
    dni: "",
    nrosocio: "",
    idGrupo: "0",
    captchaToken: null,
  });

  useEffect(() => {
    getUser().then(() => {
      if (auth) {
        history.push("/mis-datos");
      } else {
        setLoadingLogin(false);
      }
    });
  }, [auth]);

  useEffect(() => {
    setDatosFactura({});
    if (idEmpresa !== null) {
      axiosClient
        .post("datos_empresa", { id: idEmpresa })
        .then((response) => {
          setDatosEmpresa(response.data.data[0]);
          const newDatosUsuario = { ...datosUsuario };
          newDatosUsuario["idGrupo"] = response.data.data[0].id_grupo;
          if (nroDoc && nroSoc !== null) {
            newDatosUsuario["dni"] = nroDoc;
            newDatosUsuario["nrosocio"] = nroSoc;
          }
          setDatosUsuario(newDatosUsuario);
          setMainLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      if (nroDoc && nroSoc !== null) {
        const newDatosUsuario = { ...datosUsuario };
        newDatosUsuario["dni"] = nroDoc;
        newDatosUsuario["nrosocio"] = nroSoc;
        setDatosUsuario(newDatosUsuario);
        setMainLoading(false);
      } else {
        setMainLoading(false);
      }

      // else {
      //   setMainLoading(false);
      // }
    }
  }, []);

  const updateValue = (e) => {
    const newDatosUsuario = { ...datosUsuario };
    newDatosUsuario[e.target.name] = e.target.value;
    setDatosUsuario(newDatosUsuario);
  };

  const updateAlert = (type, color, bgColor, accentColor, message) => {
    var newDatosAlert = { ...datosAlert };
    newDatosAlert.type = type;
    newDatosAlert.color = color;
    newDatosAlert.bgColor = bgColor;
    newDatosAlert.accentColor = accentColor;
    newDatosAlert.message = message;
    setDatosAlert(newDatosAlert);
    setShowAlert(true);
  };

  const buscarFacturas = () => {
    setShowAlert(false);
    if (datosUsuario.dni === "" || datosUsuario.nrosocio === "") {
      updateAlert(
        "warning",
        "yellow",
        "300",
        "500",
        "Por favor, completá los dos campos"
      );
    } else {
      if (!captchaVerified) {
        updateAlert(
          "warning",
          "yellow",
          "300",
          "500",
          "Por favor, completá el reCaptcha"
        );
      } else {
        setLoading(true);
        setFacturasCliente([]);
        setArchivosUsuario([]);
        localStorage.removeItem("facturas");
        axiosClient
          .post("facturas_descargas", datosUsuario)
          .then((response) => {
            if (
              response.data.data.facturas.length === 0 &&
              response.data.data.archivos.length === 0
            ) {
              updateAlert(
                "warning",
                "yellow",
                "300",
                "500",
                "No se encontraron facturas con los datos solicitados"
              );
              recaptchaRef.current.reset();
              setLoading(false);
            } else {
              setDeudaTotal(response.data.data.total);
              setDeudaVencida(response.data.data.total_deuda);
              setFacturasCliente(response.data.data.facturas);
              setArchivosUsuario(response.data.data.archivos);
              setCaptchaVerified(false);
              recaptchaRef.current.reset();
              setLoading(false);
            }
          })
          .catch((err) => {
            if (err.response.data.code === 404) {
              updateAlert(
                "warning",
                "yellow",
                "300",
                "500",
                "No se encontraron facturas con los datos solicitados"
              );
              setLoading(false);
              setCaptchaVerified(false);
              recaptchaRef.current.reset();
            } else {
              updateAlert(
                "error",
                "red",
                "300",
                "500",
                "Hubo un error, por favor intentá de nuevo"
              );
              setLoading(false);
              setCaptchaVerified(false);
              recaptchaRef.current.reset();
            }
          });
      }
    }
  };

  const dir_ip = async () => {
    const dir_ip_v4 = await publicIp
      .v4({
        fallbackUrls: ["https://ifconfig.co/ip"],
      })
      .catch((err) => {
        console.log(null);
      });
    if (dir_ip_v4 === undefined) {
      const dir_ip_v6 = await publicIp
        .v6({
          fallbackUrls: ["https://ifconfig.co/ip"],
        })
        .catch((err) => {
          console.log(null);
        });
      if (dir_ip_v6 === undefined) {
        return "NO SE PUDO OBTENER LA IP";
      } else {
        return dir_ip_v6;
      }
    } else {
      return dir_ip_v4;
    }
  };

  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  const solicitarFactura = async (factura) => {
    var newLoadingFacturas = { ...loadingFactura };
    newLoadingFacturas[factura.id] = true;
    setLoadingFactura(newLoadingFacturas);
    setDatosFactura(factura);
    var facturas = localStorage.getItem("facturas");
    if (facturas !== null) {
      var facturas_JSON = JSON.parse(facturas);
      facturas_JSON.facturas[factura.id] = factura;
      localStorage.setItem("facturas", JSON.stringify(facturas_JSON));
    } else {
      var facturas_JSON = {
        facturas: {
          [factura.id]: factura,
        },
      };
      localStorage.setItem("facturas", JSON.stringify(facturas_JSON));
    }
    //localStorage.setItem("factura", JSON.stringify(factura));
    var date = new Date();
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var dt = date.getDate();
    var hs = date.getHours();
    var min = date.getMinutes();
    if (dt < 10) {
      dt = "0" + dt;
    }
    if (month < 10) {
      month = "0" + month;
    }
    if (hs < 10) {
      hs = "0" + hs;
    }
    if (min < 10) {
      min = "0" + min;
    }

    const payload = {
      empresa: factura.nombreempresa,
      nroDoc: factura.nroDoc,
      nroSocio: factura.nroSocio,
      fecha: year + "-" + month + "-" + dt,
      hora: hs + ":" + min,
      direccion_ip: await dir_ip(),
    };
    axiosClient
      .post("log_factura", payload)
      .then((response) => {
        newLoadingFacturas = { ...loadingFactura };
        newLoadingFacturas[factura.id] = false;
        setLoadingFactura(newLoadingFacturas);
      })
      .catch((err) => {
        newLoadingFacturas = { ...loadingFactura };
        newLoadingFacturas[factura.id] = false;
        setLoadingFactura(newLoadingFacturas);
        console.log(err);
      });
    openInNewTab("/factura?f=" + factura.id);
  };

    const onVerified = (token) => {
    const newDatosUsuario = { ...datosUsuario };
    newDatosUsuario.captchaToken = token;
    setDatosUsuario(newDatosUsuario);
    setCaptchaVerified(true);
  }; 

  const onExpired = () => {
    const newDatosUsuario = { ...datosUsuario };
    newDatosUsuario.captchaToken = null;
    setDatosUsuario(newDatosUsuario);
    setCaptchaVerified(false);
  };

  if (mainLoading || loadingLogin) {
    return (
      <div className="flex justify-center ">
        <div className="flex flex-col items-center p-4 pt-10 pb-10 justify-center bg-blue-800 rounded w-1/2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-20 w-20 animate-spin text-white"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
            />
          </svg>
          <p className="text-3xl text-white font-bold">Cargando...</p>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <div className="xl:flex-column xl:justify-center p-4 m-4 shadow-lg bg-white rounded-md ring-2 py-5 ring-indigo-600">
          <div>
            <div className="text-2xl text-center flex flex-col lg:flex-row justify-center items-center gap-4 text-gray-800 font-medium ">
              <p className="bg-indigo-400 rounded px-4 lg:px-16 py-2 text-white shadow-md">
                😱 ¿Sabías que ahora podés ver tus facturas y pagos en un solo
                lugar?
              </p>
              <Link
                to="/register"
                className="bg-green-400 shadow-md text-gray-50 px-4 py-2 rounded hover:bg-green-500 w-full lg:w-2/12"
              >
                Registrate acá
              </Link>
            </div>
          </div>
        </div>
        <div className="xl:flex-column xl:justify-center p-4 m-4 shadow-lg bg-blue-800 rounded">
          <div>
            <h1 className="text-3xl text-center text-white font-bold ">
              Mis Facturas
            </h1>
          </div>
        </div>
        <div className="xl:flex-col xl:justify-center shadow-lg p-4 m-4 bg-blue-800 rounded">
          <div className="flex flex-col lg:flex-row ">
            <div className="xl:w-6/12 w-4/10">
              <div>
                <label
                  className="block uppercase tracking-wide text-white text-xs font-bold mb-2"
                  htmlFor="dni"
                >
                  Número de DNI
                </label>
                <div className="flex flex-row w-full lg:w-3/5 items-stretch mb-4 relative h-15 bg-white rounded mb-6 shadow-lg">
                  <div className="flex -mr-px justify-center w-15 px-1 bg-gray-200 rounded-l ">
                    <span className="bg-gray-200 flex items-center leading-normal bg-white px-2 border-0 text-2xl">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5 text-mainBlue"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 2a1 1 0 00-1 1v1a1 1 0 002 0V3a1 1 0 00-1-1zM4 4h3a3 3 0 006 0h3a2 2 0 012 2v9a2 2 0 01-2 2H4a2 2 0 01-2-2V6a2 2 0 012-2zm2.5 7a1.5 1.5 0 100-3 1.5 1.5 0 000 3zm2.45 4a2.5 2.5 0 10-4.9 0h4.9zM12 9a1 1 0 100 2h3a1 1 0 100-2h-3zm-1 4a1 1 0 011-1h2a1 1 0 110 2h-2a1 1 0 01-1-1z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </span>
                  </div>
                  <input
                    type="number"
                    name="dni"
                    id="dni"
                    className="flex-shrink flex-grow  flex-auto leading-normal flex-1 border-0 h-12  px-3 self-center rounded rounded-l-none relative outline-none"
                    placeholder="Número de DNI *"
                    onChange={(e) => updateValue(e)}
                    value={datosUsuario.dni}
                  />
                </div> 
              </div>
              

            
              <label
                className="block uppercase tracking-wide text-white text-xs font-bold mb-2"
                htmlFor="nrosocio"
              >
                {datosEmpresa.identificacion === undefined
                  ? "Identificación"
                  : datosEmpresa.identificacion}
              </label>
              <div className="flex flex-row items-stretch w-full lg:w-3/5 mb-4 relative h-15 bg-white items-center rounded mb-6 shadow-lg">
                <div className="flex -mr-px justify-center w-15 px-1 bg-gray-200 rounded-l ">
                  <span className="bg-gray-200 flex items-center leading-normal bg-white px-2 border-0 text-2xl">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5 text-mainBlue"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </span>
                </div>
                <input
                  type="number"
                  name="nrosocio"
                  id="nrosocio"
                  className="flex-shrink flex-grow flex-auto leading-normal flex-1 border-0 h-12 px-3 self-center rounded rounded-l-none relative outline-none"
                  placeholder={
                    datosEmpresa.identificacion === undefined
                      ? "Identificación *"
                      : datosEmpresa.identificacion + " *"
                  }
                  onChange={(e) => updateValue(e)}
                  value={datosUsuario.nrosocio}
                />
              </div>
               <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                onChange={onVerified}
                onExpired={onExpired}
                onErrored={onExpired}
              />  
              <button
                type="submit"
                className="bg-mainBlueLight flex items-center justify-center shadow-lg uppercase font-medium py-4 text-center w-full lg:w-4/12  text-white rounded leading-tight text-lg mt-4  "
                onClick={() => buscarFacturas()}
              >
                Buscar
                {loading ? (
                  <svg
                    className="animate-spin ml-3 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                ) : null}
              </button> 
            </div>
            <div className="xl:w-8/12 xl:mr-8  w-10/12 ">
              <img src={bannerAside} className="xl:pt-8 xl:pb-6"></img>              
            </div>
          </div>  
          
        </div>
        {showAlert ? (
          <div
            className={`border border-r-0 border-t-0 border-b-0 border-l-8 border-${datosAlert.color}-${datosAlert.accentColor} flex items-center justify-between py-3 px-4 m-4 bg-${datosAlert.color}-${datosAlert.bgColor} rounded shadow-md`}
          >
            <div>
              <h1 className="flex items-center text-left text-gray-800 font-bold">
                {datosAlert.type === "warning" ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 mr-2 text-yellow-700"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M18 13V5a2 2 0 00-2-2H4a2 2 0 00-2 2v8a2 2 0 002 2h3l3 3 3-3h3a2 2 0 002-2zM5 7a1 1 0 011-1h8a1 1 0 110 2H6a1 1 0 01-1-1zm1 3a1 1 0 100 2h3a1 1 0 100-2H6z"
                      clipRule="evenodd"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 mr-2 text-red-700"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                      clipRule="evenodd"
                    />
                  </svg>
                )}

                <p className="pl-2 pr-2 lg:pl-0 lg:pr-0">
                  {datosAlert.message}
                </p>
              </h1>
            </div>
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 cursor-pointer"
                viewBox="0 0 20 20"
                fill="currentColor"
                onClick={() => setShowAlert(false)}
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
          </div>
        ) : null}

        {facturasCliente.length > 0 ? (
          <div
            className={
              facturasCliente.length > 0
                ? "p-4 bg-white rounded shadow-lg mb-3 ml-4 mr-4 transition ease-in duration-75 transform opacity-100 scale-100"
                : "p-4 bg-white rounded shadow-lg mb-3 ml-4 mr-4 transition ease-out duration-100 transform opacity-0 scale-0"
            }
          >
            <div className="xl:flex xl:justify-between xl:items-center ">
              <div>
                <div className="ml-0.5">
                  <p className="text-sm text-black font-bold mb-4 xl:mb-0">
                    {facturasCliente[0].nombre.toUpperCase() +
                      " - " +
                      facturasCliente[0].nroSocio}
                  </p>
                  <p className="text-sm text-gray-500 font-bold mb-4 xl:mb-0">
                    Facturas
                  </p>
                </div>
              </div>
              <div className="flex items-center">
                {/*                 
                <div className="p-4 rounded bg-gray-200 text-gray-800 mr-4">
                  <p>Deuda Total</p>
                  <h3 className="font-bold">{formatImporte(deudaTotal)}</h3>
                </div> 
                */}
                <div className="p-4 rounded bg-red-400 text-white">
                  <p>Abonar a la fecha</p>
                  <h3 className="font-bold">{formatImporte(deudaVencida)}</h3>
                </div>
              </div>
            </div>
            {facturasCliente.map((f) => (
              <div
                className={
                  calcularVencimiento(f.fechaVencimiento)
                    ? f.cod_barra !== 0
                      ? "rounded border-2 border-red-400 p-3 mb-2 mt-2 lg:grid lg:grid-cols-8 lg:gap-4 hover:border-red-600 transition ease-in-out duration-300"
                      : "rounded border-2 border-red-400 p-3 mb-2 mt-2 lg:grid lg:grid-cols-7 lg:gap-4 hover:border-red-600 transition ease-in-out duration-300"
                    : f.cod_barra !== 0
                    ? "border rounded p-3 mb-3 lg:grid lg:grid-cols-8 mt-2 lg:gap-4 hover:border-blue-500 transition ease-in-out duration-300 "
                    : "border rounded p-3 mb-3 lg:grid lg:grid-cols-7 mt-2 lg:gap-4 hover:border-blue-500 transition ease-in-out duration-300 "
                }
                key={f.id}
                onClick={() => addFactura(f)}
              >
                <div className="lg:border-r lg:m-0 mb-2">
                  <p className="text-sm font-bold text-gray-800">EMPRESA</p>
                  <p className="text-sm text-gray-500">{f.nombreempresa}</p>
                </div>
                <div className="lg:border-r lg:m-0 mb-2">
                  <p className="text-sm font-bold text-gray-800">
                    FECHA DE EMISIÓN
                  </p>
                  <p className="text-sm text-gray-500">{formatDate(f.fecha)}</p>
                </div>
                <div className="lg:border-r lg:m-0 mb-2">
                  <p className="text-sm font-bold text-gray-800">DESCRIPCIÓN</p>
                  <p className="text-sm text-gray-500">{f.descripcion}</p>
                </div>
                <div className="lg:border-r lg:m-0 mb-2">
                  <p className="text-sm font-bold text-gray-800">VENCIMIENTO</p>
                  <p className="text-sm text-gray-500">
                    {formatDate(f.fechaVencimiento)}
                  </p>
                </div>
                <div className="lg:border-r lg:m-0 mb-2">
                  <p className="text-sm font-bold text-gray-800">TOTAL</p>
                  <p className="text-sm text-gray-500">
                    {formatImporte(f.importe)}
                  </p>
                </div>
                <div className="lg:border-r lg:m-0 mb-2">
                  <p className="text-sm font-bold text-gray-800">SALDO</p>
                  <p className="text-sm text-gray-500">
                    {formatImporte(f.saldo)}
                  </p>
                </div>
                <div
                  className={
                    f.cod_barra !== 0
                      ? "lg:border-r lg:m-0 mb-2"
                      : "lg:m-0 mb-2"
                  }
                >
                  <p className="text-sm font-bold text-gray-800">
                    SALDO A LA FECHA
                  </p>
                  <p className="text-sm text-gray-500">
                    {formatImporte(f.saldoafecha)}
                  </p>
                </div>
                {f.cod_barra !== 0 ? (
                  <div className=" lg:m-0 mb-2">
                    <p className="text-sm font-bold text-gray-800">ACCIONES</p>
                    <button
                      className="rounded text-sm font-medium text-white bg-mainBlueLight w-full lg:w-11/12 mt-1 py-2 duration-200 hover:bg-blue-600"
                      onClick={() => solicitarFactura(f)}
                    >
                      {loadingFactura[f.id] ? (
                        <div className="flex flex-row items-center justify-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5 animate-spin mr-2"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fillRule="evenodd"
                              d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z"
                              clipRule="evenodd"
                            />
                          </svg>
                          <p>CARGANDO...</p>
                        </div>
                      ) : (
                        "DESCARGAR"
                      )}
                    </button>
                  </div>
                ) : null}
              </div>
            ))}
          </div>
        ) : null}
        {archivosUsuario.length > 0 ? (
          <div className="p-4 bg-white rounded shadow-lg mb-3 ml-4 mr-4 transition duration-500 ease-in-out">
            <div className="ml-0.5 mt-4 mb-4">
              <p className="text-sm text-black font-bold">
                {facturasCliente[0].nombre.toUpperCase() +
                  " - " +
                  facturasCliente[0].nroSocio}
              </p>
              <p className="text-sm text-gray-500 font-bold mb-4 xl:mb-0">
                Archivos
              </p>
            </div>
            {archivosUsuario.map((a) => (
              <div
                className={
                  false
                    ? "rounded border-2 border-red-400 p-3 mb-2 mt-2 lg:grid lg:grid-cols-6  lg:gap-4 hover:border-red-600 transition ease-in-out duration-300 "
                    : "border rounded p-3 mb-3 lg:grid lg:grid-cols-6 mt-2  lg:gap-4 hover:border-blue-500 transition ease-in-out duration-300 "
                }
                key={a.id}
                //onClick={() => addFactura(f)}
              >
                <div className="lg:border-r lg:m-0 mb-2">
                  <p className="text-sm font-bold text-gray-800">EMPRESA</p>
                  <p className="text-sm text-gray-500">{a.nombreempresa}</p>
                </div>
                <div className="lg:border-r lg:m-0 mb-2 col-span-4">
                  <p className="text-sm font-bold text-gray-800">DESCRIPCIÓN</p>
                  <p className="text-sm text-gray-500">{a.descripcion}</p>
                </div>
                <div className="lg:border-r lg:m-0 pr-4 mb-2 flex flex-wrap justify-start">
                  <p className="text-sm font-bold text-gray-800">ACCIONES</p>
                  {/*                 <button
                    className="rounded text-sm font-medium text-white mt-1 bg-mainBlueLight w-full  py-2 "
                  >
                    DESCARGAR
                  </button> */}
                  <a
                    className="rounded text-sm font-medium text-white text-center mt-1 bg-mainBlueLight w-full py-2 "
                    href={a.url}
                    download={a.descripcion + ".jpg"}
                  >
                    DESCARGAR
                  </a>
                </div>
              </div>
            ))}
          </div>
        ) : null}
      </div>
    );
  }
}

export default UsuarioData;
