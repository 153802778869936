import { useContext, useState, useEffect } from "react";
import ModalAltaGP from "./ModalAltaGP";
import MODAL_BLOQ_DESBLOQ from "./Modal_Bloq_Desbloq";
import UsuariosContext from "../../../../context/usuarios/usuariosContext";
import AuthContext from "../../../../context/auth/authContext";
import axiosClient from "../../../../config/axios";

function GrupoPago() {
  const usuariosContext = useContext(UsuariosContext);
  const {
    setModalGP,
    modalGP,
    setModalBloqDesbloq,
    modalBloqDesbloq,
    updateGP,
  } = usuariosContext;
  const authContext = useContext(AuthContext);
  const { header } = authContext;

  const [dataModal, setDataModal] = useState({ bloq: false, persona: null });
  const [grupoPropio, setGrupoPropio] = useState([]);
  const [grupoExterno, setGrupoExterno] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (dataModal.persona !== null) {
      setModalBloqDesbloq(true);
    }
  }, [dataModal]);

  useEffect(() => {
    if (grupoExterno.length > 0 && grupoPropio.length > 0) {
      setLoading(false);
    }
  }, [grupoExterno, grupoPropio]);

  useEffect(() => {
    axiosClient
      .get("get_grupos_pago/" + localStorage.getItem("name"), header)
      .then((response) => {
        var grupoPropioAux = [];
        var grupoExternoAux = [];
        var dniPersona = parseInt(localStorage.getItem("name"));
        response.data.data.map((dato) => {
          if (dato.nroDocPadre === dniPersona) {
            grupoPropioAux.push(dato);
          } else {
            grupoExternoAux.push(dato);
          }
        });
        grupoPropioAux = grupoPropioAux.sort((a, b) =>
          a.estado > b.estado ? 1 : -1
        );
        grupoExternoAux = grupoExternoAux.sort((a, b) =>
          a.estado > b.estado ? 1 : -1
        );
        setGrupoPropio(grupoPropioAux);
        setGrupoExterno(grupoExternoAux);
        setLoading(false);

      })
      .catch((err) => {
        console.log(err);
      });
  }, [updateGP]);

  return (
    <div className="bg-white p-6 rounded mt-4 lg:mt-0 shadow-xl flex flex-col justify-between gap-8">
      <div>
        <div className="flex flex-col items-start lg:flex-row lg:justify-between lg:items-center gap-2 lg:gap-0">
          <div className="flex flex-col">
            <h1 className="text-gray-900 text-lg lg:text-xl font-semibold">
              Mi Grupo
            </h1>
            <p className="text-gray-500 text-sm lg:text-md font-medium">
              Estas son las personas que están dentro de tu grupo de pago
            </p>
          </div>
          <button
            className="bg-green-500 flex flex-row items-center font-medium text-white lg:text-lg px-6 py-1 rounded-md focus:outline-none transition duration-300 hover:bg-green-600 justify-center w-full lg:w-36"
            onClick={() => setModalGP(true)}
          >
            <svg className="h-5 w-5 lg:w-6 lg:h-6 mr-1" viewBox="0 0 24 24">
              <path
                d="M15 12c2.21 0 4-1.79 4-4s-1.79-4-4-4s-4 1.79-4 4s1.79 4 4 4zm-9-2V7H4v3H1v2h3v3h2v-3h3v-2H6zm9 4c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"
                fill="currentColor"
              ></path>
            </svg>
            <p>Agregar</p>
          </button>
        </div>
        {loading ? (
          <div className="flex justify-center w-full border-indigo-500 border rounded-lg py-12 mt-4 bg-indigo-100">
            <svg
              className="w-16 h-16 text-indigo-600 animate-spin"
              viewBox="0 0 1024 1024"
            >
              <path
                d="M512 1024c-69.1 0-136.2-13.5-199.3-40.2C251.7 958 197 921 150 874c-47-47-84-101.7-109.8-162.7C13.5 648.2 0 581.1 0 512c0-19.9 16.1-36 36-36s36 16.1 36 36c0 59.4 11.6 117 34.6 171.3c22.2 52.4 53.9 99.5 94.3 139.9c40.4 40.4 87.5 72.2 139.9 94.3C395 940.4 452.6 952 512 952c59.4 0 117-11.6 171.3-34.6c52.4-22.2 99.5-53.9 139.9-94.3c40.4-40.4 72.2-87.5 94.3-139.9C940.4 629 952 571.4 952 512c0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 0 0-94.3-139.9a437.71 437.71 0 0 0-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.2C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7c26.7 63.1 40.2 130.2 40.2 199.3s-13.5 136.2-40.2 199.3C958 772.3 921 827 874 874c-47 47-101.8 83.9-162.7 109.7c-63.1 26.8-130.2 40.3-199.3 40.3z"
                fill="currentColor"
              ></path>
            </svg>
          </div>
        ) : (
          grupoPropio.map((miembro) => (
            <div className="border border-gray-400 rounded p-3 mb-3 mt-4 lg:grid lg:grid-cols-5 lg:gap-4">
              <div className="lg:border-r lg:m-0 mb-2">
                <p className="text-xs font-bold text-gray-400">NOMBRE</p>
                <p className="text-sm text-gray-700">{miembro.nombre}</p>
              </div>
              <div className="lg:border-r lg:m-0 mb-2">
                <p className="text-xs font-bold text-gray-400">DNI</p>
                <p className="text-sm text-gray-700">{miembro.nroDocHijo}</p>
              </div>
              <div className="lg:border-r lg:m-0 mb-2">
                <p className="text-xs font-bold text-gray-400">EMAIL</p>
                <p className="text-sm text-gray-700 relative break-words">
                  {miembro.mail === null ? "No posee" : miembro.mail}
                </p>
              </div>
              <div className="lg:m-0 mb-2 lg:border-r">
                <p className="text-xs font-bold text-gray-400">ESTADO</p>
                <p
                  className={`text-xs rounded-xl font-bold lg:font-medium tracking-wide mt-2 lg:mt-1 flex justify-center py-0.5 text-${
                    miembro.estado === 1 || miembro.estado === 0
                      ? "green-700"
                      : miembro.estado === 3
                      ? "gray-700"
                      : "red-700"
                  } bg-${
                    miembro.estado === 1 || miembro.estado === 0
                      ? "green-100"
                      : miembro.estado === 3
                      ? "gray-200"
                      : "red-100"
                  } border-${
                    miembro.estado === 1 || miembro.estado === 0
                      ? "green-500"
                      : miembro.estado === 3
                      ? "gray-500"
                      : "red-500"
                  } border-2 lg:w-9/12 opacity-90`}
                >
                  {miembro.estado === 1 || miembro.estado == 0
                    ? "HABILITADO"
                    : miembro.estado === 3
                    ? "BLOQUEADO"
                    : "DESHABILITADO"}
                </p>
              </div>
              <div className="lg:m-0 mb-2 flex flex-col justify-center items-start">
                <p className="text-xs font-bold text-gray-400">ACCIONES</p>
                <button
                  className={`bg-${
                    miembro.estado === 1 || miembro.estado === 0
                      ? "red-500"
                      : miembro.estado === 3
                      ? "gray-500"
                      : "green-500"
                  } w-full text-white px-2 rounded text-xs py-0.5 mt-1 lg:w-10/12 transition duration-300 hover:bg-${
                    miembro.estado === 1 || miembro.estado === 0
                      ? "red-600"
                      : miembro.estado === 3
                      ? "gray-500"
                      : "green-600"
                  } focus:ring-0 focus:outline-none font-semibold lg:font-medium tracking-wide flex flex-row justify-center items-center ${
                    miembro.estado === 3 ? "cursor-not-allowed" : ""
                  }`}
                  disabled={miembro.estado === 3}
                  onClick={() => {
                    setDataModal({
                      bloq: false,
                      elim: miembro.estado === 1 || miembro.estado === 0,
                      persona: miembro,
                    });
                  }}
                >
                  {miembro.estado === 1 || miembro.estado === 0 ? (
                    <svg className="w-5 h-5 mr-1" viewBox="0 0 24 24">
                      <path
                        d="M15 14c2.67 0 8 1.33 8 4v2H7v-2c0-2.67 5.33-4 8-4m0-2a4 4 0 0 1-4-4a4 4 0 0 1 4-4a4 4 0 0 1 4 4a4 4 0 0 1-4 4M5 9.59l2.12-2.13l1.42 1.42L6.41 11l2.13 2.12l-1.42 1.42L5 12.41l-2.12 2.13l-1.42-1.42L3.59 11L1.46 8.88l1.42-1.42L5 9.59z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  ) : miembro.estado === 3 ? (
                    <svg className="h-5 w-5 mr-1" viewBox="0 0 256 256">
                      <path
                        d="M202.161 200.833A103.944 103.944 0 0 0 55.171 53.837a7.398 7.398 0 0 0-1.335 1.334a103.944 103.944 0 0 0 146.998 146.99a7.42 7.42 0 0 0 1.327-1.328zM216.001 128a87.598 87.598 0 0 1-20.405 56.28L71.72 60.406A87.952 87.952 0 0 1 216 128zm-176 0a87.6 87.6 0 0 1 20.405-56.28l123.876 123.875A87.952 87.952 0 0 1 40 128z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  ) : (
                    <svg className="h-5 w-5 mr-1" viewBox="0 0 24 24">
                      <path
                        d="M21.1 12.5l1.4 1.41l-6.53 6.59L12.5 17l1.4-1.41l2.07 2.08l5.13-5.17M10 17l3 3H3v-2c0-2.21 3.58-4 8-4l1.89.11L10 17m1-13a4 4 0 0 1 4 4a4 4 0 0 1-4 4a4 4 0 0 1-4-4a4 4 0 0 1 4-4z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  )}

                  <p className="mt-0.5">
                    {miembro.estado === 1 || miembro.estado === 0
                      ? "DESHABILITAR"
                      : miembro.estado === 3
                      ? "NO DISPONIBLE"
                      : "HABILITAR"}
                  </p>
                </button>
              </div>
            </div>
          ))
        )}
      </div>

      <div>
        <h1 className="text-gray-900 text-lg lg:text-xl font-semibold">
          Grupos en los que estoy
        </h1>
        <p className="text-gray-500 text-sm lg:text-md font-medium">
          Estas son las personas que te agregaron a sus grupos de pago
        </p>
        {loading ? (
          <div className="flex justify-center w-full border-indigo-500 border rounded-lg py-12 mt-4 bg-indigo-100">
            <svg
              className="w-16 h-16 text-indigo-600 animate-spin"
              viewBox="0 0 1024 1024"
            >
              <path
                d="M512 1024c-69.1 0-136.2-13.5-199.3-40.2C251.7 958 197 921 150 874c-47-47-84-101.7-109.8-162.7C13.5 648.2 0 581.1 0 512c0-19.9 16.1-36 36-36s36 16.1 36 36c0 59.4 11.6 117 34.6 171.3c22.2 52.4 53.9 99.5 94.3 139.9c40.4 40.4 87.5 72.2 139.9 94.3C395 940.4 452.6 952 512 952c59.4 0 117-11.6 171.3-34.6c52.4-22.2 99.5-53.9 139.9-94.3c40.4-40.4 72.2-87.5 94.3-139.9C940.4 629 952 571.4 952 512c0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 0 0-94.3-139.9a437.71 437.71 0 0 0-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.2C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7c26.7 63.1 40.2 130.2 40.2 199.3s-13.5 136.2-40.2 199.3C958 772.3 921 827 874 874c-47 47-101.8 83.9-162.7 109.7c-63.1 26.8-130.2 40.3-199.3 40.3z"
                fill="currentColor"
              ></path>
            </svg>
          </div>
        ) : (
          grupoExterno.map((miembro) => (
            <div className="border border-gray-400 rounded p-3 mb-3 mt-4 lg:grid lg:grid-cols-5 lg:gap-4">
              <div className="lg:border-r lg:m-0 mb-2">
                <p className="text-xs font-bold text-gray-400">NOMBRE</p>
                <p className="text-sm text-gray-700">{miembro.nombre}</p>
              </div>
              <div className="lg:border-r lg:m-0 mb-2">
                <p className="text-xs font-bold text-gray-400">DNI</p>
                <p className="text-sm text-gray-700">{miembro.nroDocPadre}</p>
              </div>
              <div className="lg:border-r lg:m-0 mb-2">
                <p className="text-xs font-bold text-gray-400">EMAIL</p>
                <p className="text-sm text-gray-700">
                  {miembro.mail === null ? "No posee" : miembro.mail}
                </p>
              </div>
              <div className="lg:m-0 mb-2 lg:border-r">
                <p className="text-xs font-bold text-gray-400">ESTADO</p>
                <p
                  className={`text-xs rounded-xl font-bold lg:font-medium tracking-wide mt-2 lg:mt-1 flex justify-center py-0.5 text-${
                    miembro.estado === 1 || miembro.estado === 0
                      ? "green-700"
                      : "red-700"
                  } bg-${
                    miembro.estado === 1 || miembro.estado === 0
                      ? "green-100"
                      : "red-100"
                  } border-${
                    miembro.estado === 1 || miembro.estado === 0
                      ? "green-500"
                      : "red-500"
                  } border-2 lg:w-9/12 opacity-90`}
                >
                  {miembro.estado === 1 || miembro.estado === 0
                    ? "HABILITADO"
                    : "DESHABILITADO"}
                </p>
              </div>
              <div className="lg:m-0 mb-2 flex flex-col justify-center items-start">
                <p className="text-xs font-bold text-gray-400">ACCIONES</p>
                <button
                  className={`bg-${
                    miembro.estado === 1 || miembro.estado === 0
                      ? "red-500"
                      : "green-500"
                  } w-full text-white px-2 rounded text-xs py-0.5 mt-1 lg:w-10/12 transition duration-300 hover:bg-${
                    miembro.estado === 1 || miembro.estado === 0
                      ? "red-600"
                      : "green-600"
                  } focus:ring-0 focus:outline-none font-semibold lg:font-medium tracking-wide flex flex-row justify-center items-center`}
                  onClick={() => {
                    setDataModal({
                      bloq: miembro.estado === 1 || miembro.estado === 0,
                      elim: false,
                      persona: miembro,
                    });
                  }}
                >
                  {miembro.estado === 1 || miembro.estado === 0 ? (
                    <svg className="w-5 h-5 mr-1" viewBox="0 0 24 24">
                      <path
                        d="M15 14c2.67 0 8 1.33 8 4v2H7v-2c0-2.67 5.33-4 8-4m0-2a4 4 0 0 1-4-4a4 4 0 0 1 4-4a4 4 0 0 1 4 4a4 4 0 0 1-4 4M5 9.59l2.12-2.13l1.42 1.42L6.41 11l2.13 2.12l-1.42 1.42L5 12.41l-2.12 2.13l-1.42-1.42L3.59 11L1.46 8.88l1.42-1.42L5 9.59z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  ) : (
                    <svg className="h-5 w-5 mr-1" viewBox="0 0 24 24">
                      <path
                        d="M21.1 12.5l1.4 1.41l-6.53 6.59L12.5 17l1.4-1.41l2.07 2.08l5.13-5.17M10 17l3 3H3v-2c0-2.21 3.58-4 8-4l1.89.11L10 17m1-13a4 4 0 0 1 4 4a4 4 0 0 1-4 4a4 4 0 0 1-4-4a4 4 0 0 1 4-4z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  )}

                  <p className="mt-0.5">
                    {miembro.estado === 1 || miembro.estado === 0
                      ? "DESHABILITAR"
                      : "HABILITAR"}
                  </p>
                </button>
              </div>
            </div>
          ))
        )}
        
        
        
        
        



      </div>

      {modalGP ? <ModalAltaGP /> : null}
      {modalBloqDesbloq ? <MODAL_BLOQ_DESBLOQ data={dataModal} /> : null}
    </div>
  );
}

export default GrupoPago;
