import {
  RESULTADO_PERSONA,
  FACTURAS_CLIENTE,
  NOMBRE_CLIENTE,
  FACTURAS_SELECCIONADAS,
  IMPORTE_TOTAL,
  SELECCIONAR_FACTURA,
  VERIFICAR_PARCIAL,
  SET_PAGO_PARCIAL,
  SET_IMPORTE_CLIENTE,
  RECIBOS_CLIENTE,
  SET_BUSCADOR_ESTADO,
  VENCIMIENTO_CLIENTE,
  TOTAL_CLIENTE,
  RESET_STATE,
  DNI_PERSONA,
} from "../../types/index.js";

export default (state, action) => {
  switch (action.type) {
    case RESET_STATE:
      return {
        resultadoPersona: [],
        facturasCliente: [],
        nombreCliente: "",
        facturaSeleccionada: {},
        seleccionadasPagar: [],
        importeTotal: 0,
        importeCliente: 0,
        pagoParcial: "",
        pagoVerificado: false,
        recibosIDs: [],
        recibosCliente: [],
        buscadorActivo: true,
        montoPorCliente: [],
        vencimientoCliente: 0,
        totalCliente: 0,
        dniPersona: null,
      };
    case RESULTADO_PERSONA:
      return {
        ...state,
        resultadoPersona: action.payload,
      };
    case FACTURAS_CLIENTE:
      return {
        ...state,
        facturasCliente: action.payload,
      };
    case SELECCIONAR_FACTURA:
      return {
        ...state,
        facturaSeleccionada: action.payload,
      };
    case IMPORTE_TOTAL:
      return {
        ...state,
        importeTotal: action.payload,
      };
    case NOMBRE_CLIENTE:
      return {
        ...state,
        nombreCliente: action.payload,
      };
    case FACTURAS_SELECCIONADAS:
      return {
        ...state,
        seleccionadasPagar: action.payload,
      };
    case VERIFICAR_PARCIAL:
      return {
        ...state,
        pagoVerificado: action.payload,
      };

    case VENCIMIENTO_CLIENTE:
      return {
        ...state,
        vencimientoCliente: action.payload,
      };
    case TOTAL_CLIENTE:
      return {
        ...state,
        totalCliente: action.payload,
      };
    case SET_PAGO_PARCIAL:
      return {
        ...state,
        pagoParcial: action.payload,
      };
    case SET_IMPORTE_CLIENTE:
      return {
        ...state,
        importeCliente: action.payload,
      };
    case RECIBOS_CLIENTE:
      return {
        ...state,
        recibosCliente: action.payload,
      };
    case SET_BUSCADOR_ESTADO:
      return {
        ...state,
        buscadorActivo: action.payload,
      };
    case DNI_PERSONA:
      return {
        ...state,
        dniPersona: action.payload,
      };
    default:
      return state;
  }
};
