export const LOGING_SUCCESS = "LOGING_SUCCESS";
export const LOGING_ERROR = "LOGING_ERROR";
export const GET_USER = "GET_USER";
export const LOG_OUT = "LOG_OUT";
export const SET_FECHA = "SET_FECHA";

export const SET_ALERT = "SET_ALERT";
export const DELETE_ALERT = "DELETE_ALERT";

export const RESULTADO_PERSONA = "RESULTADO_PERSONA";
export const DNI_PERSONA = "DNI_PERSONA";
export const FACTURAS_SELECCIONADAS = "FACTURAS_SELECCIONADAS";
export const SELECCIONAR_FACTURA = "SELECCIONAR_FACTURA";
export const FACTURAS_CLIENTE = "FACTURAS_CLIENTE";
export const IMPORTE_TOTAL = "IMPORTE_TOTAL";
export const NOMBRE_CLIENTE = "NOMBRE_CLIENTE";
export const VERIFICAR_PARCIAL = "VERIFICAR_PARCIAL";
export const SET_PAGO_PARCIAL = "SET_PAGO_PARCIAL";
export const SET_IMPORTE_CLIENTE = "SET_IMPORTE_CLIENTE";
export const PROCESAR_PAGO = "PROCESAR_PAGO";
export const RECIBOS_CLIENTE = "RECIBOS_CLIENTE";
export const SET_BUSCADOR_ESTADO = "SET_BUSCADOR_ESTADO";
export const VENCIMIENTO_CLIENTE = "VENCIMIENTO_CLIENTE";
export const TOTAL_CLIENTE = "TOTAL_CLIENTE";
export const RESET_STATE = "RESET_STATE";

// Usuarios Types
export const SET_MODAL_GP = "SET_MODAL_GP";
export const SET_MODAL_REGISTER = "SET_MODAL_REGISTER";
export const SET_MODAL_DETALLE = "SET_MODAL_DETALLE";
export const SET_MODAL_BLOQ_DESBLOQ = "SET_MODAL_BLOQ_DESBLOQ";
export const SET_MODAL_LOADING_PREFERENCIA = "SET_MODAL_LOADING_PREFERENCIA";
