import React from "react";

import E404img from "../assets/E404.svg";

function E404() {
  
  return (
    <div className="py-8 lg:py-20">
      <div className="flex flex-wrap content-center justify-center">
        <img src={E404img} className="img-error" alt="imagen error" />
        <div className="flex flex-wrap content-center lg:">
          <div>
            <h2 className="text-4xl font-bold text-gray-700">
              ¡Oh no! Error 404
            </h2>
            <p className="text-lg font-light text-gray-500">
              La página que estás buscando no existe.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default E404;
