import { useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import axiosClient from "../config/axios";

function ConfirmarMail() {
  let location = useLocation();
  const token = new URLSearchParams(location.search).get("cod");
  const username = new URLSearchParams(location.search).get("username");
  if (token === null || token === "") {
    window.location.href = "/";
  }
  if (username === null || username === "") {
    window.location.href = "/";
  }

  const [loading, setLoading] = useState(true);
  const [textoNotificacion, setTextoNotificacion] = useState({
    title: "Estamos confirmando tu correo electrónico",
    subtitle: "Aguardá unos momentos por favor",
    progress: "Confirmando...",
    error: false,
  });

  useEffect(() => {
    if (
      token !== null &&
      token !== "" &&
      username !== null &&
      username !== ""
    ) {
      axiosClient
        .post("/v2/checkCodigoMail", { codigo: token, username: username })
        .then((response) => {
          if (response.data.error === false) {
            var newText = { ...textoNotificacion };
            newText.title = "Se confirmó correctamente tu correo electrónico";
            newText.subtitle = "Ya podés iniciar sesión en Cardinal";
            newText.progress = "Confirmación exitosa";
            newText.error = false;
            setTextoNotificacion(newText);
            setLoading(false);
          } else {
            var newText = { ...textoNotificacion };
            textoNotificacion.title =
              "No pudimos confirmar tu correo electrónico";
            newText.subtitle = "Hubo un error en el proceso";
            newText.progress = "Error al confirmar";
            newText.error = true;
            setTextoNotificacion(newText);
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
          var newText = { ...textoNotificacion };
          newText.title = "No pudimos confirmar tu correo electrónico";
          newText.subtitle = "Hubo un error en el proceso";
          newText.progress = "Error al confirmar";
          newText.error = true;
          setTextoNotificacion(newText);
          setLoading(false);
        });
    }
  }, []);

  useEffect(() => {}, [
    textoNotificacion.title,
    textoNotificacion.subtitle,
    textoNotificacion.progress,
    textoNotificacion.error,
  ]);

  return (
    <div className="p-4">
      <div className="bg-white p-4 m-4 rounded shadow-xl">
        <div className="flex justify-center items-center flex-col">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-12 w-12 text-mainBlueLight"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
            />
          </svg>
          <h1 className="font-bold text-3xl text-center">
            Confirmación de Correo Electrónico
          </h1>
        </div>
        <div className="bg-mainBlue p-4 pt-8 pb-8 lg:ml-20 lg:mr-20 mt-8 mb-8 flex justify-center text-center flex-col rounded shadow-md">
          <h2 className="font-semibold text-xl text-white">
            {textoNotificacion.title}
          </h2>
          <h2 className="font-semibold text-xl mt-2 text-white">
            {textoNotificacion.subtitle}
          </h2>
          <div className="flex justify-center mt-2">
            <div className="bg-gray-100 rounded flex flex-row mt-4 items-center justify-center gap-2 py-1 w-full lg:w-3/12 2xl:w-2/12">
              {loading ? (
                <svg
                  className="animate-spin ml-3 h-5 w-5 text-mainBlue"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              ) : textoNotificacion.error ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 text-red-500"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 text-green-400"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              )}

              <p className="text-md font-medium">
                {textoNotificacion.progress}
              </p>
            </div>
          </div>
          {textoNotificacion.progress === "Confirmación exitosa" ? (
            <div className=" flex justify-center pt-4 -mb-4">
              <Link
                to="/login"
                className="px-4 py-1 font-bold text-white bg-mainBlueLight transition duration-200 hover:bg-indigo-600 rounded flex items-center lg:mb-0"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 mr-3"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1"
                  />
                </svg>
                Ir a Inicio de Sesión
              </Link>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default ConfirmarMail;
