import { useState, useEffect, useContext } from "react";
import axiosClient from "../../../config/axios";
import AuthContext from "../../../context/auth/authContext";
import AlertContext from "../../../context/alerts/alertContext";

function DatosPersonales() {
  const authContext = useContext(AuthContext);
  const { header, userID, getUser, checkGrupo, auth } = authContext;
  const alertContext = useContext(AlertContext);
  const { setAlert } = alertContext;

  const [habilitarEdicion, setHabilitarEdicion] = useState(false);
  const [misDatos, setMisDatos] = useState({
    nombre: "",
    apellido: "",
    nroDoc: "",
    fechaNac: { date: "" },
    mail: "",
    nroDoc: "",
    tipoDoc: "",
    genero: "",
    celular: "",
    direccion: "",
    provincia: "",
    ciudad: "",
    codigoPostal: "",
    telefono: "",
  });
  const [datosGenerales, setDatosGenerales] = useState({});
  const [loadingDatos, setLoadingDatos] = useState(true);
  const [actualizando, setActualizando] = useState(false);

  useEffect(() => {
    getUser().then(() => {
      if (!checkGrupo("USUARIO")) {
        window.location = "/login";
      } else {
        axiosClient
          .get(`v2/mis_datos/${userID}`, header)
          .then((response) => {
            if (response.data.error === false) {
              setMisDatos(response.data.data.persona);
              var datosGenerales = {
                provincias: response.data.data.provincias,
                generos: response.data.data.generos,
              };
              setDatosGenerales(datosGenerales);
            } else {
              setAlert(
                "Hubo un problema recuperando tus datos. Por favor, intentá más tarde",
                "red-300"
              );
            }
            setLoadingDatos(false);
          })
          .catch((err) => {
            console.log(err);
            setAlert(
              "Hubo un problema recuperando tus datos. Por favor, intentá más tarde",
              "red-300"
            );
          });
      }
    });
  }, []);

  useEffect(() => {
    if (!checkGrupo("USUARIO")) {
      window.location = "/login";
    }
  }, [auth]);

  const updateValue = (e) => {
    var newDatos = { ...misDatos };
    newDatos[e.target.name] = e.target.value;
    setMisDatos(newDatos);
  };

  const actualizarDatos = () => {
    setActualizando(true);
    var payload = {
      idPersona: userID,
      mail: misDatos.mail,
      nombre: misDatos.nombre,
      apellido: misDatos.apellido,
      fechaNac:
        misDatos.fechaNac.date !== undefined
          ? misDatos.fechaNac.date
          : misDatos.fechaNac,
      nroDoc: misDatos.nroDoc.toString(),
      tipoDoc: misDatos.tipoDoc,
      genero: misDatos.genero,
      celular: misDatos.celular,
      direccion: misDatos.direccion,
      provincia: misDatos.provincia,
      ciudad: misDatos.ciudad,
      codigoPostal: misDatos.codigoPostal,
      telefono: misDatos.telefono,
    };
    axiosClient
      .post("v2/editar_persona", payload, header)
      .then((response) => {
        if (response.data.error === false) {
          setAlert("Datos actualizados correctamente", "green-300");
          setHabilitarEdicion(false);
        } else {
          setAlert(
            "Hubo un error actualizando tus datos. Por favor, intentá más tarde",
            "red-300"
          );
        }
        setActualizando(false);
      })
      .catch((err) => {
        console.log(err);
        setAlert(
          "Hubo un error actualizando tus datos. Por favor, intentá más tarde",
          "red-300"
        );
        setActualizando(false);
      });
  };

  return (
    <div className="bg-white p-6 rounded mt-4 lg:mt-0 shadow-xl lg:h-xl">
      <h1 className="text-gray-900 text-xl font-semibold mb-6">
        Datos Personales
      </h1>
      <div className="lg:grid lg:grid-cols-3 gap-6 space-y-4 lg:space-y-0 mt-4 mb-4">
        <div className="flex flex-col">
          <label className="text-gray-500 text-xs font-medium mb-1">
            NOMBRE
          </label>
          <input
            name="nombre"
            value={misDatos.nombre}
            className={
              habilitarEdicion
                ? "bg-gray-100 rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none"
                : "bg-gray-200 cursor-not-allowed rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none text-gray-600"
            }
            placeholder={loadingDatos ? "Cargando..." : "Nombre"}
            disabled={!habilitarEdicion}
            onChange={(e) => updateValue(e)}
          />
        </div>
        <div className="flex flex-col">
          <label className="text-gray-500 text-xs font-medium mb-1">
            APELLIDO
          </label>
          <input
            name="apellido"
            value={misDatos.apellido}
            className={
              habilitarEdicion
                ? "bg-gray-100 rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none"
                : "bg-gray-200 cursor-not-allowed rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none text-gray-600"
            }
            placeholder={loadingDatos ? "Cargando..." : "Apellido"}
            disabled={!habilitarEdicion}
            onChange={(e) => updateValue(e)}
          />
        </div>
        <div className="flex flex-col">
          <label className="text-gray-500 text-xs font-medium mb-1">
            GÉNERO
          </label>
          <select
            name="genero"
            value={misDatos.genero === null ? "N" : misDatos.genero}
            className={
              habilitarEdicion
                ? "bg-gray-100 rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none h-10"
                : "bg-gray-300 cursor-not-allowed rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none h-10"
            }
            placeholder="Género"
            disabled={!habilitarEdicion}
            onChange={(e) => updateValue(e)}
          >
            {!loadingDatos && datosGenerales.generos.length > 0 ? (
              datosGenerales.generos.map((g) => (
                <option value={g.clave}>{g.descripcion}</option>
              ))
            ) : (
              <option value="NE">No Especificado</option>
            )}
          </select>
        </div>
        <div className="flex flex-col">
          <label className="text-gray-500 text-xs font-medium mb-1">
            TIPO DOCUMENTO
          </label>
          <select
            name="tipoDoc"
            value="DNI"
            className="bg-gray-300 cursor-not-allowed rounded px-2 py-2 h-10 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none"
            placeholder="Género"
            disabled
          >
            <option value="DNI">DNI</option>
            <option value="CUIT">CUIT</option>
          </select>
        </div>
        <div className="flex flex-col">
          <label className="text-gray-500 text-xs font-medium mb-1">
            NRO. DOCUMENTO
          </label>
          <input
            name="nroDoc"
            value={misDatos.nroDoc}
            className="bg-gray-200 cursor-not-allowed rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none text-gray-600"
            placeholder={loadingDatos ? "Cargando..." : "Número de Documento"}
            disabled
            //onChange={(e) => updateValue(e)}
          />
        </div>
        <div className="flex flex-col">
          <label className="text-gray-500 text-xs font-medium mb-1">
            FECHA DE NACIMIENTO
          </label>
          <input
            name="fechaNac"
            value={
              misDatos.fechaNac.date !== undefined
                ? misDatos.fechaNac.date.slice(0, 10)
                : misDatos.fechaNac
            }
            className={
              habilitarEdicion
                ? "bg-gray-100 rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none"
                : "bg-gray-200 cursor-not-allowed rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none"
            }
            type="date"
            placeholder={loadingDatos ? "Cargando..." : "Fecha de Nacimiento"}
            disabled={!habilitarEdicion}
            onChange={(e) => updateValue(e)}
          />
        </div>
        <div className="flex flex-col">
          <label className="text-gray-500 text-xs font-medium mb-1">
            TELÉFONO CELULAR
          </label>
          <input
            name="celular"
            value={misDatos.celular}
            className={
              habilitarEdicion
                ? "bg-gray-100 rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none"
                : "bg-gray-200 cursor-not-allowed rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none text-gray-600"
            }
            placeholder={loadingDatos ? "Cargando..." : "Teléfono Celular"}
            disabled={!habilitarEdicion}
            onChange={(e) => updateValue(e)}
          />
        </div>
        <div className="flex flex-col">
          <label className="text-gray-500 text-xs font-medium mb-1">
            TELÉFONO FIJO
          </label>
          <input
            name="telefono"
            value={misDatos.telefono}
            className={
              habilitarEdicion
                ? "bg-gray-100 rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none"
                : "bg-gray-200 cursor-not-allowed rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none text-gray-600"
            }
            placeholder={loadingDatos ? "Cargando..." : "Teléfono Fijo"}
            disabled={!habilitarEdicion}
            onChange={(e) => updateValue(e)}
          />
        </div>
        <div className="flex flex-col">
          <label className="text-gray-500 text-xs font-medium mb-1">
            EMAIL
          </label>
          <input
            name="mail"
            value={misDatos.mail}
            className="bg-gray-200 cursor-not-allowed rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none text-gray-600"
            type="email"
            placeholder={loadingDatos ? "Cargando..." : "Email"}
            disabled
            //onChange={(e) => updateValue(e)}
          />
        </div>
      </div>
      <div className="flex lg:flex-row flex-col w-full lg:gap-6 lg:mt-6 space-y-4 lg:space-y-0">
        <div className="flex flex-col w-full">
          <label className="text-gray-500 text-xs font-medium mb-1">
            DIRECCIÓN
          </label>
          <input
            name="direccion"
            value={misDatos.direccion}
            className={
              habilitarEdicion
                ? "bg-gray-100 rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none"
                : "bg-gray-200 cursor-not-allowed rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none text-gray-600"
            }
            placeholder={loadingDatos ? "Cargando..." : "Dirección"}
            disabled={!habilitarEdicion}
            onChange={(e) => updateValue(e)}
          />
        </div>
        <div className="flex flex-col  w-full">
          <label className="text-gray-500 text-xs font-medium mb-1">
            PROVINCIA
          </label>
          <select
            name="provincia"
            value={misDatos.provincia}
            className={
              habilitarEdicion
                ? "bg-gray-100 rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none h-10 disabled:opacity-50"
                : "bg-gray-300 cursor-not-allowed rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none h-10 disabled:opacity-50"
            }
            placeholder="Provincia"
            disabled={!habilitarEdicion}
            onChange={(e) => updateValue(e)}
          >
            {!loadingDatos && datosGenerales.provincias.length > 0 ? (
              datosGenerales.provincias.map((p) => (
                <option value={p.clave}>{p.descripcion}</option>
              ))
            ) : (
              <option value="NE">No Especificado</option>
            )}
          </select>
        </div>
        <div className="flex flex-col  w-full">
          <label className="text-gray-500 text-xs font-medium mb-1">
            CIUDAD
          </label>
          <input
            name="ciudad"
            value={misDatos.ciudad}
            className={
              habilitarEdicion
                ? "bg-gray-100 rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none"
                : "bg-gray-200 cursor-not-allowed rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none text-gray-600"
            }
            placeholder={loadingDatos ? "Cargando..." : "Ciudad"}
            disabled={!habilitarEdicion}
            onChange={(e) => updateValue(e)}
          />
        </div>
        <div className="flex flex-col  w-full">
          <label className="text-gray-500 text-xs font-medium mb-1">
            CÓDIGO POSTAL
          </label>
          <input
            name="codigoPostal"
            value={misDatos.codigoPostal}
            className={
              habilitarEdicion
                ? "bg-gray-100 rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none"
                : "bg-gray-200 cursor-not-allowed rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none text-gray-600"
            }
            placeholder={loadingDatos ? "Cargando..." : "Código Postal"}
            disabled={!habilitarEdicion}
            onChange={(e) => updateValue(e)}
          />
        </div>
      </div>
      <div className="flex justify-start mt-6">
        <button
          onClick={() => {
            if (habilitarEdicion) {
              actualizarDatos();
            } else {
              setHabilitarEdicion(!habilitarEdicion);
            }
          }}
          className={
            habilitarEdicion
              ? actualizando
                ? "bg-gray-400 flex flex-row items-center font-medium text-gray-800 text-lg px-6 py-1 rounded-md focus:outline-none cursor-not-allowed w-full lg:w-3/12 2xl:w-2/12 justify-center lg:mt-1.5"
                : "bg-green-500 flex flex-row items-center font-medium text-white text-lg px-6 py-1 rounded-md focus:outline-none transition duration-300 hover:bg-green-600 w-full lg:w-3/12 2xl:w-3/12 justify-center lg:mt-1.5"
              : "bg-mainBlueLight flex flex-row items-center font-medium text-white text-lg px-6 py-1 rounded-md focus:outline-none transition duration-300 hover:bg-indigo-600 w-full lg:w-2/12 2xl:w-1/12 justify-center lg:mt-1.5"
          }
          disabled={actualizando}
        >
          {habilitarEdicion ? (
            actualizando ? (
              <svg
                className="animate-spin h-5 w-5 mr-2 mb-0.5 text-mainBlue"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 mr-1 mb-0.5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            )
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 mr-1 mb-0.5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
              />
            </svg>
          )}
          {habilitarEdicion
            ? actualizando
              ? "Actualizando"
              : "Guardar Cambios"
            : "Editar"}
        </button>
      </div>
    </div>
  );
}

export default DatosPersonales;
