import React, { useReducer, useState } from "react";
import usuariosContext from "./usuariosContext";
import usuariosReducer from "./usuariosReducer";

import {
  SET_MODAL_GP,
  SET_MODAL_REGISTER,
  SET_MODAL_DETALLE,
  SET_MODAL_BLOQ_DESBLOQ,
  SET_MODAL_LOADING_PREFERENCIA,
} from "../../types/index.js";

const UsuariosState = (props) => {
  const initialState = {
    modalGP: false,
    modalRegister: false,
    modalDetalle: false,
    modalBloqDesbloq: false,
    modalLoadingPreferencia: false,
  };

  const [state, dispatch] = useReducer(usuariosReducer, initialState);
  const [updateGP, setUpdateGP] = useState(false);

  const setModalGP = (state) => {
    dispatch({ type: SET_MODAL_GP, payload: state });
  };

  const setModalRegister = (state) => {
    dispatch({ type: SET_MODAL_REGISTER, payload: state });
  };

  const setModalDetalle = (state) => {
    dispatch({ type: SET_MODAL_DETALLE, payload: state });
  };

  const setModalBloqDesbloq = (state) => {
    dispatch({ type: SET_MODAL_BLOQ_DESBLOQ, payload: state });
  };

  const setModalLoadingPreferencia = (state) => {
    dispatch({ type: SET_MODAL_LOADING_PREFERENCIA, payload: state });
  };

  return (
    <usuariosContext.Provider
      value={{
        modalGP: state.modalGP,
        modalRegister: state.modalRegister,
        modalDetalle: state.modalDetalle,
        modalBloqDesbloq: state.modalBloqDesbloq,
        modalLoadingPreferencia: state.modalLoadingPreferencia,
        setModalGP,
        setModalRegister,
        setModalDetalle,
        setModalBloqDesbloq,
        setModalLoadingPreferencia,
        updateGP,
        setUpdateGP,
      }}
    >
      {props.children}
    </usuariosContext.Provider>
  );
};

export default UsuariosState;
