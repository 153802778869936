import { useContext, useState } from "react";
import axiosClient from "../../../../config/axios";
import UsuariosContext from "../../../../context/usuarios/usuariosContext";
import AuthContext from "../../../../context/auth/authContext";
import AlertContext from "../../../../context/alerts/alertContext";

function ModalAltaGP() {
  const usuariosContext = useContext(UsuariosContext);
  const { setModalGP, updateGP, setUpdateGP } = usuariosContext;
  const authContext = useContext(AuthContext);
  const { header } = authContext;
  const alertContext = useContext(AlertContext);
  const { setAlert } = alertContext;
  const [dniPersona, setDNIPersona] = useState("");
  const [nombrePersona, setNombrePersona] = useState("");

  const agregarPersona = async () => {
    if (dniPersona === "" || nombrePersona === "") {
      //alerta
      return false;
    }
    var payload = {
      dniPadre: localStorage.getItem("name"),
      dniHijo: dniPersona,
      nombre: nombrePersona,
    };
    axiosClient
      .post("add_grupo_pago", payload, header)
      .then((response) => {
        console.log(response);
        setUpdateGP(!updateGP);
        setAlert("Persona agregada exitosamente", "green-300");
        setModalGP(false);
      })
      .catch((err) => {
        var mensaje = err.response.data.data
          ? err.response.data.data
          : "Hubo un error agregando la persona a su grupo";
        setAlert(mensaje, "red-300");
        setModalGP(false);
        console.log(err);
      });
  };

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto py-10">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <div
          className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl lg:align-middle transform transition-all w-full lg:w-2/5"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="bg-white px-4 my-8 lg:my-2 pb-4 sm:p-6 sm:pb-4">
            <div className="flex justify-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-11 w-11 mb-1 text-green-400"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z"
                />
              </svg>
            </div>
            <div>
              <h2 className="font-bold text-xl text-center">
                Agregar Persona a Grupo de Pago
              </h2>
              <div className="flex justify-center">
                <p className="text-lg text-center text-gray-600 lg:w-3/4 mb-4">
                  <br></br>
                  Para agregar una Persona a tu Grupo de Pago, ingresá el DNI y
                  un nombre para identificar a la misma. Luego, hacé click en{" "}
                  <b>Agregar</b>
                </p>
              </div>
            </div>
            <div className="flex flex-col items-center gap-4">
              <div className="mt-2 flex flex-wrap items-stretch relative h-15 bg-gray-100  rounded w-full">
                <div className="flex -mr-px w-15 px-1">
                  <span className="flex items-center leading-normal bg-gray-100 px-2 border-1 text-2xl">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M10 6H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V8a2 2 0 00-2-2h-5m-4 0V5a2 2 0 114 0v1m-4 0a2 2 0 104 0m-5 8a2 2 0 100-4 2 2 0 000 4zm0 0c1.306 0 2.417.835 2.83 2M9 14a3.001 3.001 0 00-2.83 2M15 11h3m-3 4h2"
                      />
                    </svg>
                  </span>
                </div>
                <input
                  type="number"
                  name="dni"
                  id="dni"
                  className="w-100 h-10 bg-gray-200 flex-shrink py-2 flex-grow flex-auto leading-normal border-0 px-3 self-center rounded rounded-l-none relative outline-none"
                  placeholder="DNI de la Persona"
                  onChange={(e) => {
                    if (parseInt(e.target.value) > 0 || e.target.value === "") {
                      setDNIPersona(e.target.value);
                    }
                  }}
                  //disabled={aceptandoArchivo}
                  value={dniPersona}
                />
              </div>
              <div className="mt-2 flex flex-wrap items-stretch relative h-15 bg-gray-100  rounded w-full">
                <div className="flex -mr-px w-15 px-1">
                  <span className="flex items-center leading-normal bg-gray-100 px-2 border-1 text-2xl">
                    <svg className="h-6 w-6" viewBox="0 0 48 48">
                      <g
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="4"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <circle cx="24" cy="11" r="7"></circle>
                        <path d="M4 41c0-8.837 8.059-16 18-16"></path>
                        <path d="M31 42l10-10l-4-4l-10 10v4h4z"></path>
                      </g>
                    </svg>
                  </span>
                </div>
                <input
                  type="text"
                  name="nombre"
                  id="nombre"
                  className="w-100 h-10 bg-gray-200 flex-shrink py-2 flex-grow flex-auto leading-normal border-0 px-3 self-center rounded rounded-l-none relative outline-none"
                  placeholder="Nombre identificatorio de la Persona"
                  maxLength="12"
                  onChange={(e) => setNombrePersona(e.target.value)}
                  //disabled={aceptandoArchivo}
                  value={nombrePersona}
                />
              </div>
              {/*               <button
                type="button"
                className="px-4 py-1 h-10 font-bold text-white bg-mainBlueLight rounded flex items-center lg:mb-0 mt-2 w-full lg:w-1/4 justify-center"
                onClick={() => {
                  setModalGP(false);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 mr-3"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                    clip-rule="evenodd"
                  />
                </svg>
                Buscar
              </button> */}
            </div>
          </div>
          <div className="bg-gray-50 flex flex-row justify-between p-6 pb-4 pt-2 gap-2">
            <button
              type="button"
              className={`px-4 py-1 font-bold transition duration-300 rounded flex items-center lg:mb-0 w-full  justify-center ${
                nombrePersona === "" || dniPersona === ""
                  ? "cursor-not-allowed text-white bg-gray-500"
                  : "text-white bg-green-500 hover:bg-green-600 "
              }`}
              onClick={() => {
                agregarPersona();
              }}
              disabled={nombrePersona === "" || dniPersona === ""}
            >
              <svg className="h-5 w-5 mr-3" viewBox="0 0 256 256">
                <path
                  d="M128 24a104 104 0 1 0 104 104A104.12 104.12 0 0 0 128 24zm49.531 85.79l-58.672 56a8.024 8.024 0 0 1-11.062 0l-29.328-28a8.007 8.007 0 0 1 11.062-11.58l23.797 22.728l53.14-50.727a8.007 8.007 0 0 1 11.063 11.578z"
                  fill="currentColor"
                ></path>
              </svg>
              <p className="mt-0.5">Agregar</p>
            </button>
            <button
              type="button"
              className="px-4 py-1 font-bold text-white bg-red-500 hover:bg-red-600 transition duration-300 rounded flex items-center lg:mb-0 w-full justify-center"
              onClick={() => {
                setModalGP(false);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="h-5 w-5 mr-3"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
              <p className="mt-0.5">Cerrar</p>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalAltaGP;
