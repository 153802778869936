import React, { useContext, useEffect } from "react";
import LogoCS from "../../assets/factura/17.png";
import LogoObstreticasD1 from "../../assets/factura/18-withText.png";
import LogoObstreticasD5 from "../../assets/factura/19.png";
import LogoObstreticasD6 from "../../assets/factura/20.png";
import LogoCOPBA2 from "../../assets/factura/21.png";
import LogoCOPBAX from "../../assets/factura/24.png";
import FrontiniLogo from "../../assets/factura/frontini-logo.svg";
import LogoProvincia from "../../assets/factura/logoProvincia-Net.png";
import LogoPagoFacil from "../../assets/factura/logoPago-Facil.png";
import { useBarcode } from "react-barcodes";
import { useLocation } from "react-router-dom";

function UsuarioFactura() {
  let location = useLocation();
  const idFactura = new URLSearchParams(location.search).get("f");
  if (idFactura === null || idFactura === "") {
    window.location.href = "/";
  }
  const facturas = JSON.parse(localStorage.getItem("facturas"));
  if (facturas === null || facturas.facturas[idFactura] === undefined) {
    window.location.href = "/";
  }
  const datosFactura = facturas.facturas[idFactura];

  useEffect(() => {
    if (Object.keys(datosFactura).length === 0) {
      window.location.href = "/";
    }
  }, []);

  //Formatea las fechas segun lo necesario en las vistas.
  const formatDate = (data) => {
    var date = new Date(data);

    if (!isNaN(date.getTime())) {
      var day = date.getUTCDate().toString();
      var month = (date.getUTCMonth() + 1).toString();
      var fecha =
        (day[1] ? day : "0" + day[0]) +
        "/" +
        (month[1] ? month : "0" + month[0]) +
        "/" +
        date.getFullYear();
      return fecha;
    }
  };

  // Funcion para formatear el importe
  const formatImporte = (data) => {
    const total = new Intl.NumberFormat("de-DE", {
      minimumFractionDigits: 2,
    }).format(data);

    return "$ " + total;
  };

  const { inputRef } = useBarcode({
    value: datosFactura.cod_barra,
  });

  return (
    <div className="p-6 bg-white h-screen">
      <div className="border border-gray-800 p-4">
        <div className="flex justify-between mb-6 items-end">
          <div className="flex justify-center items-center ">
            {/* 
              Este hardcodeo es horrible, pero comienza en el backend, y se arrastra hasta acá 
              asi que ¯\_(ツ)_/¯
            */}
            {datosFactura.ID_empresa === 17 ? (
              <img src={LogoCS} className="h-30 mr-4"></img>
            ) : null}
            {datosFactura.ID_empresa === 18 ? (
              <img src={LogoObstreticasD1} className="h-28 mr-4"></img>
            ) : null}
            {datosFactura.ID_empresa === 19 ? (
              <img src={LogoObstreticasD5} className="h-28"></img>
            ) : null}
            {datosFactura.ID_empresa === 20 ? (
              <img src={LogoObstreticasD6} className="h-28 w-90"></img>
            ) : null}
            {datosFactura.ID_empresa === 21 ? (
              <img src={LogoCOPBA2} className="h-20 mr-4 mb-4"></img>
            ) : null}
            {datosFactura.ID_empresa === 24 ? (
              <img src={LogoCOPBAX} className="h-40 mr-4"></img>
            ) : null}
          </div>
          <div>
            {datosFactura.ID_empresa === 21 ? (
              <img src={LogoPagoFacil} width={120} height={100}></img>
            ) : (
              <img src={LogoProvincia} width={200} height={200}></img>
            )}
          </div>
        </div>

        <div className="border border-gray-800 p-4">
          <p className="mb-3 text-xl">
            <span className="font-bold">Descripción: </span>
            {datosFactura.descripcion}
          </p>
          <p className="mb-3 text-xl">
            <span className="font-bold">Nombre, Apellido y Matrícula: </span>{" "}
            {datosFactura.nombre + " - " + datosFactura.nroSocio}
          </p>
          {datosFactura.fechaVencimiento1 !== datosFactura.fechaVencimiento2 ? (
            <div>
              <p className="mb-3 text-xl">
                <span className="font-bold">Vencimiento 1: </span>{" "}
                {formatDate(datosFactura.fechaVencimiento1)}
              </p>
              <p className="mb-3 text-xl">
                <span className="font-bold">Vencimiento 2: </span>{" "}
                {formatDate(datosFactura.fechaVencimiento2)}
              </p>
            </div>
          ) : (
            <p className="mb-3 text-xl">
              <span className="font-bold">Vencimiento 1: </span>{" "}
              {formatDate(datosFactura.fechaVencimiento1)}
            </p>
          )}

          <p className="mb-3 text-xl">
            <span className="font-bold">Importe: </span>{" "}
            {formatImporte(datosFactura.importe)}
          </p>
        </div>

        <div className="py-6 border border-gray-800 mt-5 flex justify-center">
          <svg ref={inputRef} />
        </div>
      </div>
      <div className="flex justify-between mt-5">
        <div>
          <p className="text-xs text-gray-600">Desarrollado por:</p>
          <img src={FrontiniLogo} className="h-12"></img>
        </div>
        <div>
          <p className="text-xs text-gray-600">
            Impreso:{" "}
            {formatDate(new Date()) +
              " " +
              new Date().getHours() +
              ":" +
              new Date().getMinutes()}
          </p>
          {/*           <p className="text-xs text-gray-600">
            Fecha:{" "}
            
          </p> */}
        </div>
      </div>
    </div>
  );
}

export default UsuarioFactura;
