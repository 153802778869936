import { useContext } from "react";
import CobrosContext from "../../../context/cobros/cobrosContext";

function ModalCarga(props) {
  const cobrosContext = useContext(CobrosContext);
  const { formatImporte } = cobrosContext;

  if (props.factura) {
    return (
      <div className="fixed z-10 inset-0 overflow-y-auto py-10">
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div className="fixed inset-0 transition-opacity" aria-hidden="true">
            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>

          <div
            className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl lg:align-middle transform transition-all w-full lg:w-3/5"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div className="bg-white px-4 my-8 lg:my-1 sm:p-6">
              <div className="flex justify-center">
                <svg
                  aria-hidden="true"
                  role="img"
                  className="iconify iconify--tablerh-11 w-11 mb-1 text-blue-500"
                  preserveAspectRatio="xMidYMid meet"
                  viewBox="0 0 24 24"
                >
                  <g
                    className="icon-tabler"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path d="M5 21V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v16l-3-2l-2 2l-2-2l-2 2l-2-2l-3 2"></path>
                    <path d="M14 8h-2.5a1.5 1.5 0 0 0 0 3h1a1.5 1.5 0 0 1 0 3H10m2 0v1.5m0-9V8"></path>
                  </g>
                </svg>
              </div>
              <div>
                <h2 className="font-bold text-xl text-center">
                  Estamos preparando todo para el Pago
                </h2>
                <div className="bg-indigo-200 py-4 border-2 mt-4 rounded border-indigo-600 flex justify-center flex-col items-center gap-4">
                  <div className="bg-white rounded-xl font-medium text-lg px-4 text-center border border-indigo-700 py-6 gap-4 flex flex-col justify-center">
                    <p>Vas a pagar:</p>
                    <p className="text-blue-700">
                      {props.factura.descripcion + " - " + props.factura.nombre}
                    </p>
                    <p>Por un monto de:</p>
                    <p className="text-xl text-green-600">
                      {formatImporte(
                        parseFloat(props.factura.importe) +
                          parseFloat(props.factura.importe2)
                      )}
                    </p>
                  </div>
                  <svg
                    className="w-12 h-12 animate-spin text-indigo-700"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm0 18a8 8 0 1 1 8-8a8 8 0 0 1-8 8z"
                      opacity=".5"
                      fill="currentColor"
                    ></path>
                    <path
                      d="M20 12h2A10 10 0 0 0 12 2v2a8 8 0 0 1 8 8z"
                      fill="currentColor"
                    >
                      <animateTransform
                        attributename="transform"
                        type="rotate"
                        from="0 12 12"
                        to="360 12 12"
                        dur="1s"
                        repeatcount="indefinite"
                      ></animateTransform>
                    </path>
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ModalCarga;
