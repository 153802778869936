import { useEffect, useState, useContext } from "react";
import Loading from "../Loading";
import axiosClient from "../../config/axios";
import AuthContext from "../../context/auth/authContext";
import CobrosContext from "../../context/cobros/cobrosContext";

function MisArchivos() {
  const authContext = useContext(AuthContext);
  const { header, getUser, checkGrupo, auth, username } = authContext;
  const dniPersona = username;
  const cobrosContext = useContext(CobrosContext);
  const { formatDate } = cobrosContext;
  const [loadingFacturas, setLoadingFacturas] = useState(true);
  const [archivos, setArchivos] = useState([]);
  const [hayError, setHayError] = useState(false);

  useEffect(() => {
    getUser().then(() => {
      if (!checkGrupo("USUARIO")) {
        window.location = "/login";
      } else {
        var payload = {
          dni: dniPersona,
        };
        axiosClient
          .post(`v2/get_mis_archivos`, payload, header)
          .then((response) => {
            setArchivos(response.data.data);
            setLoadingFacturas(false);
          })
          .catch((err) => {
            console.log(err);
            setHayError(true);
            setLoadingFacturas(false);
          });
      }
    });
  }, []);

  useEffect(() => {
    if (!checkGrupo("USUARIO")) {
      window.location = "/login";
    }
  }, [auth]);

  const openInNewTab = (url,id_emp,id_arch) => {
    let payload={
      id_archivo:id_arch,
      id_empresa:id_emp
    }
    axiosClient
          .post(`v2/set_log`, payload, header)
          .then((response) => {
            
          })
          .catch((err) => {
            console.log(err);
            setHayError(true);            
          });

    window.open(url, "_blank").focus();
  };

  if (auth) {
    return (
      <div className="p-4 lg:mt-11 lg:mb-6">
        <div className="grid grid-cols-1 gap-4">
          <div className="p-4 bg-white shadow-lg rounded">
            <div className="lg:flex lg:items-start lg:justify-between mb-2">
              <div>
                <h1 className="text-xl font-bold text-gray-800">
                  Mis Archivos
                </h1>
              </div>
            </div>
            <div className="mt-0 mb-3">
              <p className="text-sm text-gray-500 ">
                Acá podés revisar el listado de tus archivos subidos
              </p>
            </div>
            {loadingFacturas ? <Loading color="mainBlueLight" /> : null}
            {archivos && archivos.length > 0 ? (
              archivos.map((ar) => (
                <div
                  className={
                    "border rounded p-3 mb-3 lg:grid lg:grid-cols-4 lg:gap-4"
                  }
                  key={ar.id}
                >
                  {/*                   <div className="lg:border-r lg:m-0 mb-2">
                    <p className="text-sm font-bold text-gray-800">
                      NRO. ARCHIVO
                    </p>
                    <p className="text-sm text-gray-500">{ar.id}</p>
                  </div> */}
                  <div className="lg:border-r g:m-0 mb-2">
                    <p className="text-sm font-bold text-gray-800">
                      FECHA SUBIDA
                    </p>
                    <p className="text-sm text-gray-500">
                      {ar.fecha !== null
                        ? formatDate(ar.fecha)
                        : "No Especificada"}
                    </p>
                  </div>
                  <div className="lg:border-r lg:m-0 mb-2">
                    <p className="text-sm font-bold text-gray-800">EMPRESA</p>
                    <p className="text-sm text-gray-500">{ar.descripcion}</p>
                  </div>
                  <div className="lg:border-r lg:m-0 mb-2">
                    <p className="text-sm font-bold text-gray-800">
                      NOMBRE ARCHIVO
                    </p>
                    <p className="text-sm text-gray-500">
                      {" "}
                      {ar.nombre !== null ? ar.nombre : "No Especificado"}
                    </p>
                  </div>
                  <div className="lg:m-0 mb-2 flex justify-center">
                    <button
                      className="bg-mainBlueLight text-white font-semibold rounded  focus:outline-none py-1 transition duration-300 hover:bg-indigo-500 w-full flex flex-row justify-center items-center gap-2"
                      onClick={() => openInNewTab(ar.url,ar.ID_empresa,ar.id)}
                    >
                      <svg className="w-6 h-6" viewBox="0 0 16 16">
                        <g fill="none">
                          <path
                            d="M4.75 3.5c-.69 0-1.25.56-1.25 1.25v6.5c0 .69.56 1.25 1.25 1.25h6.5c.69 0 1.25-.56 1.25-1.25v-1.5a.75.75 0 0 1 1.5 0v1.5A2.75 2.75 0 0 1 11.25 14h-6.5A2.75 2.75 0 0 1 2 11.25v-6.5A2.75 2.75 0 0 1 4.75 2h1.5a.75.75 0 0 1 0 1.5h-1.5zM8 2.75A.75.75 0 0 1 8.75 2h4.5a.75.75 0 0 1 .75.75v4.5a.75.75 0 0 1-1.5 0V4.561l-3.22 3.22A.75.75 0 1 1 8.22 6.72l3.22-3.22H8.75A.75.75 0 0 1 8 2.75z"
                            fill="currentColor"
                          ></path>
                        </g>
                      </svg>
                      Ver Archivo
                    </button>
                  </div>
                </div>
              ))
            ) : !loadingFacturas && !hayError ? (
              <div className="flex justify-center">
                <p className="text-xl bg-indigo-500 py-2 px-2 font-medium rounded lg:w-3/4 2xl:w-1/2 text-white text-center mt-6 mb-6 flex flex-col lg:flex-row lg:gap-2 justify-center">
                  <p className="text-2xl lg:text-xl">
                    <svg className="h-7 w-7" viewBox="0 0 48 48">
                      <g fill="none" strokeWidth="4" strokeLinejoin="round">
                        <path
                          d="M5 8a2 2 0 0 1 2-2h12l5 6h17a2 2 0 0 1 2 2v26a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V8z"
                          fill="#2F88FF"
                          stroke="#000"
                        ></path>
                        <path
                          d="M30 28l-6.007 6L18 28.013M24 20v14"
                          stroke="#fff"
                          strokeLinecap="round"
                        ></path>
                      </g>
                    </svg>
                  </p>{" "}
                  Aún no tenés archivos subidos. Cuando te suban uno nuevo, van
                  a aparecer acá.{" "}
                </p>
              </div>
            ) : !loadingFacturas && hayError ? (
              <div className="flex justify-center">
                <p className="text-xl bg-red-400 py-2 px-2 font-medium rounded lg:w-3/4 2xl:w-1/2 text-white text-center mt-6 mb-6 flex flex-col lg:flex-row lg:gap-2 justify-center">
                  <p className="text-2xl lg:text-xl">🙁</p> ¡Ups! Hubo un error
                  recuperando tus archivos. Por favor, intentá más tarde.{" "}
                </p>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  } else {
    window.location = "/";
  }
}

export default MisArchivos;
