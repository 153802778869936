import React, { useState, useEffect } from "react";
import LogoCS from "../../../assets/factura/17.png";
import LogoObstreticasD1 from "../../../assets/factura/18-withText.png";
import LogoObstreticasD5 from "../../../assets/factura/19.png";
import LogoObstreticasD6 from "../../../assets/factura/20.png";
import LogoCOPBA2 from "../../../assets/factura/21.png";
import LogoCOPBAX from "../../../assets/factura/24.png";
import FrontiniLogo from "../../../assets/factura/frontini-logo.svg";
import LogoCardinal from "../../../assets/cardinal-color.svg";
import LogoProvincia from "../../../assets/factura/logoProvincia-Net.png";
import LogoPagoFacil from "../../../assets/factura/logoPago-Facil.png";
import { useBarcode } from "react-barcodes";
import { useLocation } from "react-router-dom";

function FacturaDescarga() {
  let location = useLocation();
  const idFactura = new URLSearchParams(location.search).get("f");
  /*   if (idFactura === null || idFactura === "") {
    window.location.href = "/";
  } */
  const facturas = JSON.parse(localStorage.getItem("facturas"));
  if (facturas === null) {
    window.location.href = "/";
  }
  const datosFactura = facturas.filter(
    (factura) => factura.id.toString() === idFactura
  )[0];

  const [imprimiendo, setImprimiendo] = useState(false);

  useEffect(() => {
    if (Object.keys(datosFactura).length === 0) {
      window.location.href = "/";
    }
  }, []);

  //Formatea las fechas segun lo necesario en las vistas.
  const formatDate = (data) => {
    var date = new Date(data);

    if (!isNaN(date.getTime())) {
      var day = date.getUTCDate().toString();
      var month = (date.getUTCMonth() + 1).toString();
      var fecha =
        (day[1] ? day : "0" + day[0]) +
        "/" +
        (month[1] ? month : "0" + month[0]) +
        "/" +
        date.getFullYear();
      return fecha;
    }
  };

  // Funcion para formatear el importe
  const formatImporte = (data) => {
    const total = new Intl.NumberFormat("de-DE", {
      minimumFractionDigits: 2,
    }).format(data);

    return "$ " + total;
  };

  const getNowTime = () => {
    var now = new Date();
    var hora = "";
    var minutos = "";
    if (now.getHours() < 10) {
      hora = "0" + now.getHours();
    } else {
      hora = now.getHours();
    }
    if (now.getMinutes() < 10) {
      minutos = "0" + now.getMinutes();
    } else {
      minutos = now.getMinutes();
    }
    return hora + ":" + minutos;
  };

  const imprimirFactura = () => {
    setImprimiendo(true);
  };

  useEffect(() => {
    if (imprimiendo) {
      window.print();
      setImprimiendo(false);
    }
  }, [imprimiendo]);

  const { inputRef } = useBarcode({
    value: datosFactura.cod_barra,
  });

  return (
    <div className="p-6 bg-white h-screen">
      <div className="border border-gray-800 p-4">
        <div className="flex justify-between mb-6 items-start">
          <div className="flex justify-center items-center">
            {1 === 1 ? <img src={LogoCS} className="h-30 mr-4"></img> : null}
            {datosFactura.ID_empresa === 18 ? (
              <img src={LogoObstreticasD1} className="h-28 mr-4"></img>
            ) : null}
            {datosFactura.ID_empresa === 19 ? (
              <img src={LogoObstreticasD5} className="h-28"></img>
            ) : null}
            {datosFactura.ID_empresa === 20 ? (
              <img src={LogoObstreticasD6} className="h-28 w-90"></img>
            ) : null}
            {datosFactura.ID_empresa === 21 ? (
              <img src={LogoCOPBA2} className="h-20 mr-4 mb-4"></img>
            ) : null}
            {datosFactura.ID_empresa === 24 ? (
              <img src={LogoCOPBAX} className="h-40 mr-4"></img>
            ) : null}
          </div>
          <p className="font-bold text-2xl">{datosFactura.nombre_empresa}</p>
        </div>

        <div className="border border-gray-800 p-4 rounded">
          <p className="mb-3 text-xl">
            <span className="font-bold">Descripción: </span>
            {datosFactura.descripcion}
          </p>
          <p className="mb-3 text-xl">
            <span className="font-bold">Nombre, Apellido y Matrícula: </span>{" "}
            {datosFactura.nombre + " - " + datosFactura.nroSocio}
          </p>
          {datosFactura.fechaVencimiento1 !== datosFactura.fechaVencimiento2 ? (
            <div className="flex flex-row justify-between">
              <div className="flex flex-col">
                {" "}
                <p className="mb-3 text-xl">
                  <span className="font-bold">Fecha 1° Vencimiento: </span>{" "}
                  {formatDate(datosFactura.fechaVencimiento1)}
                </p>{" "}
                <p className="mb-3 text-xl">
                  <span className="font-bold">Importe: </span>{" "}
                  {formatImporte(datosFactura.importe)}
                </p>
              </div>

              <div className="flex flex-col justify-end">
                {" "}
                <p className="mb-3 text-xl">
                  <span className="font-bold">Fecha 2° Vencimiento: </span>{" "}
                  {formatDate(datosFactura.fechaVencimiento2)}
                </p>{" "}
                <p className="mb-3 text-xl">
                  <span className="font-bold">Importe: </span>{" "}
                  {formatImporte(datosFactura.importe2)}
                </p>
              </div>
            </div>
          ) : (
            <div className="flex flex-col">
              {" "}
              <p className="mb-3 text-xl">
                <span className="font-bold">Fecha 1° Vencimiento: </span>{" "}
                {formatDate(datosFactura.fechaVencimiento1)}
              </p>{" "}
              <p className="mb-3 text-xl">
                <span className="font-bold">Importe: </span>{" "}
                {formatImporte(datosFactura.importe)}
              </p>
            </div>
          )}
        </div>
        <div className="flex flex-row justify-between items-center mt-6 mb-6 p-1 pt-0 pb-0">
          <img className="h-10" src={LogoCardinal}></img>
          <img className="h-10" src={LogoProvincia}></img>
        </div>
        <div className="mt-6 border border-gray-800 flex justify-center mb-2 rounded">
          <svg ref={inputRef} />
        </div>
      </div>
      <div className="flex justify-between mt-5">
        <div>
          <p className="text-xs text-gray-600">Desarrollado por:</p>
          <img src={FrontiniLogo} className="h-12"></img>
        </div>
        <div>
          <p className="text-xs text-gray-600">
            Impreso: {formatDate(new Date()) + " " + getNowTime()}
          </p>
          {/*           <p className="text-xs text-gray-600">
            Fecha:{" "}
            
          </p> */}
        </div>
      </div>
      <div className="flex justify-center">
        {!imprimiendo && (
          <button
            className="flex justify-center items-center font-bold bg-mainBlueLight w-full mt-10 py-2 rounded text-2xl text-white transition duration-300 hover:bg-indigo-500"
            onClick={() => imprimirFactura()}
          >
            IMPRIMIR
          </button>
        )}
      </div>
    </div>
  );
}

export default FacturaDescarga;
