import React from "react";
import ReactDOM from "react-dom";
import "./assets/index.css";
import App from "./App";

/*
 * Agregar estilos si los borra el PurgeCSS en el build:
 * bg-red-300
 * bg-red-500
 * bg-gray-100
 * border-gray-500
 * bg-green-100
 * bg-green-300
 * bg-yellow-300
 * bg-yellow-400
 * bg-yellow-500
 * bg-purple-400
 * bg-green-400
 * bg-mainBlueLight
 * text-mainBlueLight
 * text-purple-700
 * text-green-700
 * text-gray-700
 * border-yellow-500
 * border-red-500
 */

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
