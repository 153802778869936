import {
  LOGING_SUCCESS,
  LOGING_ERROR,
  GET_USER,
  LOG_OUT,
  SET_FECHA,
} from "../../types/index.js";

export default (state, action) => {
  switch (action.type) {
    case GET_USER:
      localStorage.setItem("username", action.payload.username);
      localStorage.setItem("userId", action.payload.userId);
      localStorage.setItem("grupos", JSON.stringify(action.payload.grupos));
      localStorage.setItem(
        "permission",
        JSON.stringify(action.payload.permission)
      );
      localStorage.setItem("fecha", action.payload.fecha);
      localStorage.setItem("hora", action.payload.hora);
      return {
        ...state,
        auth: true,
        token: localStorage.getItem("token"),
        username: localStorage.getItem("username"),
        name: localStorage.getItem("name"),
        userId: parseInt(localStorage.getItem("userId")),
        grupos: JSON.parse(localStorage.getItem("grupos")),
        puntoCobro: localStorage.getItem("puntoCobro"),
        datosCobrador: JSON.parse(localStorage.getItem("datosCobrador")),
        permission: JSON.parse(localStorage.getItem("permission")),
        fecha: localStorage.getItem("fecha"),
        hora: localStorage.getItem("hora"),
      };

    case LOGING_SUCCESS:
      localStorage.setItem("token", action.payload.token);
      localStorage.setItem("username", action.payload.username);
      if (action.payload.nombre) {
        localStorage.setItem("name", action.payload.nombre);
      } else {
        localStorage.setItem("name", action.payload.username);
      }
      localStorage.setItem("userId", action.payload.userId);
      localStorage.setItem("grupos", JSON.stringify(action.payload.grupos));
      localStorage.setItem(
        "permission",
        JSON.stringify(action.payload.permission)
      );
      return {
        ...state,
        auth: true,
        token: localStorage.getItem("token"),
        username: localStorage.getItem("username"),
        name: localStorage.getItem("name"),
        userId: parseInt(localStorage.getItem("userId")),
        grupos: JSON.parse(localStorage.getItem("grupos")),
        puntoCobro: localStorage.getItem("puntoCobro"),
        datosCobrador: JSON.parse(localStorage.getItem("datosCobrador")),
        permission: JSON.parse(localStorage.getItem("permission")),
      };
    case LOGING_ERROR:
      localStorage.removeItem("token");
      localStorage.removeItem("username");
      localStorage.removeItem("name");
      localStorage.removeItem("userId");
      localStorage.removeItem("grupos");
      localStorage.removeItem("permission");
      localStorage.removeItem("datosCobrador");
      localStorage.removeItem("puntoCobro");
      localStorage.removeItem("fecha");
      localStorage.removeItem("hora");
      return {
        ...state,
        token: null,
        auth: false,
        username: null,
        puntoCobro: null,
        grupos: [],
      };
    case LOG_OUT:
      localStorage.removeItem("token");
      localStorage.removeItem("username");
      localStorage.removeItem("name");
      localStorage.removeItem("userId");
      localStorage.removeItem("grupos");
      localStorage.removeItem("permission");
      localStorage.removeItem("datosCobrador");
      localStorage.removeItem("puntoCobro");
      localStorage.removeItem("fecha");
      localStorage.removeItem("hora");
      return {
        ...state,
        token: null,
        auth: false,
        username: null,
        puntoCobro: null,
        grupos: [],
      };
    case SET_FECHA:
      return {
        ...state,
        fecha: action.payload,
      };
    default:
      return state;
  }
};
