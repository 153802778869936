import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import UsuarioData from "./components/Usuario/UsuarioData";

import Nav from "./components/Nav";
import Footer from "./components/Footer";
import Alert from "./components/Alert";
import E404 from "./components/E404";

import AuthState from "./context/auth/authState";
import AlertState from "./context/alerts/alertState";
import CobrosState from "./context/cobros/cobrosState";
import UsuariosState from "./context/usuarios/usuariosState";
import UsuarioFactura from "./components/Usuario/UsuarioFactura";
import Login from "./components/Login";
import Register from "./components/Register";
import Confirmacion from "./components/ConfirmarMail";
import RecuperarPass from "./components/RecuperarPass";

//Rutas de Usuario Protegidas
import MisDatos from "./components/Usuario/MisDatos";
import MisFacturas from "./components/Usuario/MisFacturas/MisFacturas";
import MisPagos from "./components/Usuario/MisPagos/MisPagos";
import MisArchivos from "./components/Usuario/MisArchivos";
import FacturaDescarga from "./components/Usuario/MisFacturas/FacturaDescarga";
import NotificarPago from "./components/Usuario/MisFacturas/NotificarPago";

function App() {
  return (
    <AlertState>
      <AuthState>
        <CobrosState>
          <UsuariosState>
            <Router>
              <div className="flex flex-col h-screen justify-between">
                <Nav />
                <div className="fixed right-0 top-0 mx-4 my-24">
                  <Alert />
                </div>
                <Switch>
                  <Route exact path="/">
                    <UsuarioData />
                  </Route>
                  <Route exact path="/factura">
                    <UsuarioFactura />
                  </Route>
                  <Route exact path="/register">
                    <Register />
                  </Route>
                  <Route exact path="/mis-datos">
                    <MisDatos />
                  </Route>
                  <Route exact path="/mis-facturas">
                    <MisFacturas />
                  </Route>
                  <Route exact path="/mis-pagos">
                    <MisPagos />
                  </Route>
                  <Route exact path="/mis-archivos">
                    <MisArchivos />
                  </Route>
                  <Route exact path="/factura-descarga">
                    <FacturaDescarga />
                  </Route>
                  <Route exact path="/pagos/aprobado">
                    <NotificarPago />
                  </Route>
                  <Route component={Confirmacion} exact path="/confirmacion" />
                  <Route
                    component={RecuperarPass}
                    exact
                    path="/recuperar_password"
                  />
                  <Route exact path="/login">
                    <Login />
                  </Route>
                  <Route component={E404} />
                </Switch>
                <Footer />
              </div>
            </Router>
          </UsuariosState>
        </CobrosState>
      </AuthState>
    </AlertState>
  );
}

export default App;
