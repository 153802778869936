import {
  SET_MODAL_BLOQ_DESBLOQ,
  SET_MODAL_DETALLE,
  SET_MODAL_GP,
  SET_MODAL_REGISTER,
  SET_MODAL_LOADING_PREFERENCIA,
} from "../../types/index.js";

export default (state, action) => {
  switch (action.type) {
    case SET_MODAL_GP:
      return {
        modalGP: action.payload,
      };
    case SET_MODAL_REGISTER:
      return {
        modalRegister: action.payload,
      };
    case SET_MODAL_DETALLE:
      return {
        modalDetalle: action.payload,
      };
    case SET_MODAL_BLOQ_DESBLOQ:
      return {
        modalBloqDesbloq: action.payload,
      };
    case SET_MODAL_LOADING_PREFERENCIA:
      return {
        modalLoadingPreferencia: action.payload,
      };

    default:
      return state;
  }
};
