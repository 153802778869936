import { useEffect, useState, useContext } from "react";
import Loading from "../../Loading";
import axiosClient from "../../../config/axios";
import AuthContext from "../../../context/auth/authContext";
import CobrosContext from "../../../context/cobros/cobrosContext";
import UsuariosContext from "../../../context/usuarios/usuariosContext";
import ModalCarga from "./ModalCarga";

function MisFacturas() {
  const authContext = useContext(AuthContext);
  const { header, userID, getUser, checkGrupo, auth } = authContext;
  const cobrosContext = useContext(CobrosContext);
  const { formatDate, formatImporte, calcularVencimiento } = cobrosContext;
  const usuariosContext = useContext(UsuariosContext);
  const { modalLoadingPreferencia, setModalLoadingPreferencia } =
    usuariosContext;
  const [loadingFacturas, setLoadingFacturas] = useState(true);
  /* const [facturas, setFacturas] = useState([]); */
  const [facturasPropias, setFacturasPropias] = useState(null);
  const [facturasGrupo, setFacturasGrupo] = useState(null);
  const [hayError, setHayError] = useState(false);
  const [paymentModalOpen, setPaymentModalOpen] = useState({});
  const [preferenceId, setPreferenceId] = useState(null);
  const [facturaSeleccionada, setFacturaSeleccionada] = useState(null);

  useEffect(() => {
    getUser().then(() => {
      if (!checkGrupo("USUARIO")) {
        window.location = "/login";
      } else {
        axiosClient
          .get(`v2/mis_facturas/${userID}`, header)
          .then((response) => {
            //setFacturas(response.data.data.facturas);
            var facturasPropias = { ...response.data.data.facturas };
            var aux = { ...response.data.data };
            delete aux["facturas"];
            var facturasGrupo = aux;
            var GP_ARRAY = [];
            var PROP_ARRAY = [];
            var MERGED_ARRAY = [];
            for (var key in facturasGrupo) {
              if (facturasGrupo.hasOwnProperty(key)) {
                GP_ARRAY.push(facturasGrupo[key]);
                MERGED_ARRAY.push(facturasGrupo[key]);
              }
            }
            for (var key in facturasPropias) {
              if (facturasPropias.hasOwnProperty(key)) {
                PROP_ARRAY.push(facturasPropias[key]);
                MERGED_ARRAY.push(facturasPropias[key]);
              }
            }
            setFacturasGrupo(GP_ARRAY);
            setFacturasPropias(PROP_ARRAY);

            localStorage.setItem("facturas", JSON.stringify(MERGED_ARRAY));
            setLoadingFacturas(false);
          })
          .catch((err) => {
            console.log(err);
            setHayError(true);
            setLoadingFacturas(false);
          });
      }
    });
  }, []);

  const addCheckout = () => {
    const mp = new window.MercadoPago(
      "TEST-891c851b-20b5-4d8b-97ad-5438f35e8089",
      {
        locale: "es-AR",
      }
    );

    // Inicializa el checkout
    mp.checkout({
      preference: {
        id: preferenceId,
      },
      autoOpen: true,
    });
    setModalLoadingPreferencia(false);
    setFacturaSeleccionada(null);
  };

  useEffect(() => {
    if (preferenceId) {
      // con el preferenceId en mano, inyectamos el script de mercadoPago
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = "https://sdk.mercadopago.com/js/v2";
      script.addEventListener("load", addCheckout); // Cuando cargue el script, se ejecutará la función addCheckout
      document.body.appendChild(script);
    }
  }, [preferenceId]);

  const generarPreferencia = async (factura) => {
    var payload = {
      nomCliente: process.env.REACT_APP_NOMBRE_CLIENTE,
      infoAdicional: factura.id,
      items: [
        {
          titulo: factura.nombre + " - " + factura.descripcion,
          descripcion:
            factura.descripcion +
            " || " +
            factura.nombre +
            " || " +
            factura.nroDoc.toString(),
          cantidad: 1,
          precio_unitario:
            parseFloat(factura.importe) + parseFloat(factura.importe2),
        },
      ],
    };
    axiosClient
      .post("preferencias", payload, header)
      .then((response) => {
        setPreferenceId(response.data.data.preferencia.id_preferencia_mp);
      })
      .catch((err) => {
        console.log(err);
        setModalLoadingPreferencia(false);
      });
  };

  useEffect(() => {
    if (facturaSeleccionada !== null) {
      console.log(facturaSeleccionada);
      setModalLoadingPreferencia(true);
      generarPreferencia(facturaSeleccionada);
    }
  }, [facturaSeleccionada]);

  useEffect(() => {
    if (!checkGrupo("USUARIO")) {
      window.location = "/login";
    }
  }, [auth]);

  useEffect(() => {
    if (facturasPropias !== null && facturasGrupo !== null) {
      setLoadingFacturas(false);
    }
  }, [facturasGrupo, facturasPropias]);

  const handlePaymentModal = (id) => {
    const newPaymentModal = { ...paymentModalOpen };
    newPaymentModal[id] = !newPaymentModal[id];
    for (var [key] of Object.entries(newPaymentModal)) {
      if (key !== id[0].toString()) {
        newPaymentModal[key] = false;
      }
    }
    setPaymentModalOpen(newPaymentModal);
  };

  const openInNewTab = (url) => {
    window.open(url, "_blank").focus();
  };

  if (auth) {
    return (
      <div className="p-4 lg:mt-11 lg:mb-6">
        <div className="grid grid-cols-1 gap-4">
          <div className="p-4 bg-white shadow-lg rounded">
            <div className="containerBotonPago" id="containerBotonPago"></div>
            <div className="lg:flex lg:items-start lg:justify-between mb-2">
              <div>
                <h1 className="text-xl font-bold text-gray-800">
                  Mis Facturas
                </h1>
              </div>
            </div>
            <div className="mt-0 mb-3">
              <p className="text-sm text-gray-500 ">
                Acá podés revisar el listado de tus facturas pendientes
              </p>
            </div>
            {loadingFacturas ? <Loading color="mainBlueLight" /> : null}
            {!loadingFacturas && facturasPropias && facturasGrupo ? (
              <div>
                <h1 className="text-lg font-medium lg:w-60 text-center py-2 rounded-lg bg-indigo-500 text-white px-4  mt-2 mb-2">
                  Tus facturas pendientes
                </h1>
                {facturasPropias.length > 0 ? (
                  facturasPropias.map((f) => (
                    <div
                      className={
                        calcularVencimiento(f.fechaVencimiento1)
                          ? "border-2 border-red-500 rounded p-3 mb-3 lg:grid lg:grid-cols-7 lg:gap-4"
                          : "border rounded p-3 mb-3 lg:grid lg:grid-cols-7 lg:gap-4"
                      }
                      key={f.id}
                    >
                      {/*                   <div className="lg:border-r lg:m-0 mb-2">
                      <p className="text-sm font-bold text-gray-800">NRO.</p>
                      <p className="text-sm text-gray-500">{f.numero}</p>
                    </div> */}
                      {/*                   <div className="lg:border-r lg:m-0 mb-2">
                      <p className="text-sm font-bold text-gray-800">NOMBRE</p>
                      <p className="text-sm text-gray-500">{f.nombre}</p>
                    </div> */}
                      <div className="lg:border-r lg:m-0 mb-2">
                        <p className="text-sm font-bold text-gray-800">
                          EMPRESA
                        </p>
                        <p className="text-sm text-gray-500">
                          {f.nombre_empresa}
                        </p>
                      </div>
                      <div className="lg:border-r lg:m-0 mb-2">
                        <p className="text-sm font-bold text-gray-800">
                          DESCRIPCIÓN
                        </p>
                        <p className="text-sm text-gray-500">
                          {f.nombre + " - " + f.descripcion}
                        </p>
                      </div>
                      <div className="lg:border-r g:m-0 mb-2">
                        <p className="text-sm font-bold text-gray-800">
                          FECHA EMISIÓN
                        </p>
                        <p className="text-sm text-gray-500">
                          {formatDate(f.fecha)}
                        </p>
                      </div>
                      <div className="lg:border-r lg:m-0 mb-2">
                        <p className="text-sm font-bold text-gray-800">
                          FECHA VENCIMIENTO
                        </p>
                        <p
                          className={
                            calcularVencimiento(f.fechaVencimiento1)
                              ? "text-sm text-red-500"
                              : "text-sm text-gray-500"
                          }
                        >
                          {formatDate(f.fechaVencimiento1)}
                        </p>
                      </div>
                      <div className="lg:border-r lg:m-0 mb-2">
                        <p className="text-sm font-bold text-gray-800">
                          IMPORTE
                        </p>
                        <p
                          className={
                            calcularVencimiento(f.fechaVencimiento1)
                              ? "text-sm text-red-500"
                              : "text-sm text-gray-500"
                          }
                        >
                          {calcularVencimiento(f.fechaVencimiento1)
                            ? formatImporte(
                                (
                                  parseFloat(f.importe) + parseFloat(f.importe2)
                                ).toString()
                              )
                            : formatImporte(f.importe)}
                        </p>
                      </div>
                      <div className="lg:m-0 mb-2 lg:border-r">
                        <p className="text-sm font-bold text-gray-800">
                          SALDO A LA FECHA
                        </p>
                        <p className="text-sm text-gray-500">
                          {formatImporte(f.saldo)}
                        </p>
                      </div>
                      <div className="lg:m-0 mb-2 flex items-center xl:justify-center w-full">
                        <div className="relative w-full">
                          <button
                            className="px-4 py-1 font-bold text-white bg-mainBlueLight rounded flex items-center justify-center lg:m-0 mt-5 w-full transition duration-300 hover:bg-indigo-500"
                            onClick={() => handlePaymentModal([f.id])}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              className="h-7 w-7 mr-2 "
                            >
                              <path
                                fillRule="evenodd"
                                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                clipRule="evenodd"
                              />
                            </svg>
                            Pagar
                          </button>
                          <div
                            className={
                              paymentModalOpen[f.id]
                                ? "transition ease-out duration-100 transform opacity-100 scale-100 z-10 origin-top-left xl:origin-top-right xl:right-0 absolute lg:w-42 2xl:w-full rounded-md shadow-lg py-2 bg-white border-2 border-green-500 mt-1 w-full"
                                : "transition ease-in duration-75 transform opacity-0 scale-0 z-10 origin-top-left xl:origin-top-right xl:right-0 absolute lg:w-42 2xl:w-48 w-full border-2 border-indigo-500 rounded-md shadow-lg py-1 bg-white"
                            }
                          >
                            {f.medioDePago.map((medio) => (
                              <button
                                type="button"
                                className="flex w-full px-4 py-2 text-sm text-gray-700 hover:bg-green-400 hover:text-white justify-start items-center"
                                role="menuitem"
                                key={
                                  "MEDIO_DE_PAGO_" + medio.nombre + "_" + f.id
                                }
                                onClick={() => {
                                  if (
                                    medio.nombre === "Bapro" ||
                                    medio.nombre === "PFacil"
                                  ) {
                                    openInNewTab("/factura-descarga?f=" + f.id);
                                  } else {
                                    if (medio.nombre === "MercadoPago") {
                                      setFacturaSeleccionada(f);
                                      //generarPreferencia(f);
                                    }
                                  }
                                }}
                              >
                                <svg
                                  className="h-5 w-5 mr-2"
                                  viewBox="0 0 432 384"
                                >
                                  <path
                                    d="M192 299v-22h-43v-42h86v-22h-64q-9 0-15.5-6t-6.5-15v-64q0-9 6.5-15t15.5-6h21V85h43v22h42v42h-85v22h64q9 0 15 6t6 15v64q0 9-6 15t-15 6h-21v22h-43zM384 21q18 0 30.5 12.5T427 64v256q0 18-12.5 30.5T384 363H43q-18 0-30.5-12.5T0 320V64q0-18 12.5-30.5T43 21h341zm0 299V64H43v256h341z"
                                    fill="currentColor"
                                  ></path>
                                </svg>
                                <p className="mt-0.5 font-medium">
                                  {medio.descripcion}
                                </p>
                              </button>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="p-4 mt-4 mb-4 bg-indigo-200 rounded text-center font-bold text-gray-600 border-2 border-indigo-600 text-xl">
                    {" "}
                    No tenés facturas pendientes propias{" "}
                  </div>
                )}
                <h1 className="text-lg font-medium lg:w-4/12 2xl:w-60 text-left py-2 rounded-lg bg-indigo-500 text-white px-4 mt-2 mb-2">
                  Facturas pendientes de tu Grupo de Pago
                </h1>
                {facturasGrupo.length > 0 ? (
                  facturasGrupo.map((f) => (
                    <div
                      className={
                        calcularVencimiento(f.fechaVencimiento1)
                          ? "border-2 border-red-500 rounded p-3 mb-3 lg:grid lg:grid-cols-7 lg:gap-4"
                          : "border rounded p-3 mb-3 lg:grid lg:grid-cols-7 lg:gap-4"
                      }
                      key={f.id}
                    >
                      {/*                   <div className="lg:border-r lg:m-0 mb-2">
                      <p className="text-sm font-bold text-gray-800">NRO.</p>
                      <p className="text-sm text-gray-500">{f.numero}</p>
                    </div> */}
                      {/*                   <div className="lg:border-r lg:m-0 mb-2">
                      <p className="text-sm font-bold text-gray-800">NOMBRE</p>
                      <p className="text-sm text-gray-500">{f.nombre}</p>
                    </div> */}
                      <div className="lg:border-r lg:m-0 mb-2">
                        <p className="text-sm font-bold text-gray-800">
                          EMPRESA
                        </p>
                        <p className="text-sm text-gray-500">
                          {f.nombre_empresa}
                        </p>
                      </div>
                      <div className="lg:border-r lg:m-0 mb-2">
                        <p className="text-sm font-bold text-gray-800">
                          DESCRIPCIÓN
                        </p>
                        <p className="text-sm text-gray-500">
                          {f.nombre + " - " + f.descripcion}
                        </p>
                      </div>
                      <div className="lg:border-r g:m-0 mb-2">
                        <p className="text-sm font-bold text-gray-800">
                          FECHA EMISIÓN
                        </p>
                        <p className="text-sm text-gray-500">
                          {formatDate(f.fecha)}
                        </p>
                      </div>
                      <div className="lg:border-r lg:m-0 mb-2">
                        <p className="text-sm font-bold text-gray-800">
                          FECHA VENCIMIENTO
                        </p>
                        <p
                          className={
                            calcularVencimiento(f.fechaVencimiento1)
                              ? "text-sm text-red-500"
                              : "text-sm text-gray-500"
                          }
                        >
                          {formatDate(f.fechaVencimiento1)}
                        </p>
                      </div>
                      <div className="lg:border-r lg:m-0 mb-2">
                        <p className="text-sm font-bold text-gray-800">
                          IMPORTE
                        </p>
                        <p
                          className={
                            calcularVencimiento(f.fechaVencimiento1)
                              ? "text-sm text-red-500"
                              : "text-sm text-gray-500"
                          }
                        >
                          {calcularVencimiento(f.fechaVencimiento1)
                            ? formatImporte(
                                (
                                  parseFloat(f.importe) + parseFloat(f.importe2)
                                ).toString()
                              )
                            : formatImporte(f.importe)}
                        </p>
                      </div>
                      <div className="lg:m-0 mb-2 lg:border-r">
                        <p className="text-sm font-bold text-gray-800">
                          SALDO A LA FECHA
                        </p>
                        <p className="text-sm text-gray-500">
                          {formatImporte(f.saldo)}
                        </p>
                      </div>
                      <div className="lg:m-0 mb-2 flex items-center xl:justify-center w-full">
                        <div className="relative w-full">
                          {/* <button
                            className="px-4 py-1 font-bold text-white bg-mainBlueLight rounded flex items-center justify-center lg:m-0 mt-5 w-full transition duration-300 hover:bg-indigo-500"
                            onClick={() => handlePaymentModal([f.id])}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              className="h-7 w-7 mr-2 "
                            >
                              <path
                                fillRule="evenodd"
                                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                clipRule="evenodd"
                              />
                            </svg>
                            Pagar
                          </button> */}
                          <div
                            className={
                              paymentModalOpen[f.id]
                                ? "transition ease-out duration-100 transform opacity-100 scale-100 z-10 origin-top-left xl:origin-top-right xl:right-0 absolute lg:w-42 2xl:w-full rounded-md shadow-lg py-2 bg-white border-2 border-green-500 mt-1 w-full"
                                : "transition ease-in duration-75 transform opacity-0 scale-0 z-10 origin-top-left xl:origin-top-right xl:right-0 absolute lg:w-42 2xl:w-48 w-full border-2 border-indigo-500 rounded-md shadow-lg py-1 bg-white"
                            }
                          >
                            {f.medioDePago.map((medio) => (
                              <button
                                type="button"
                                className="flex w-full px-4 py-2 text-sm text-gray-700 hover:bg-green-400 hover:text-white justify-start items-center"
                                role="menuitem"
                                key={
                                  "MEDIO_DE_PAGO_" + medio.nombre + "_" + f.id
                                }
                                onClick={() => {
                                  if (
                                    medio.nombre === "Bapro" ||
                                    medio.nombre === "PFacil"
                                  ) {
                                    openInNewTab("/factura-descarga?f=" + f.id);
                                  } else {
                                    if (medio.nombre === "MercadoPago") {
                                      setFacturaSeleccionada(f);
                                      //generarPreferencia(f);
                                    }
                                  }
                                }}
                              >
                                <svg
                                  className="h-5 w-5 mr-2"
                                  viewBox="0 0 432 384"
                                >
                                  <path
                                    d="M192 299v-22h-43v-42h86v-22h-64q-9 0-15.5-6t-6.5-15v-64q0-9 6.5-15t15.5-6h21V85h43v22h42v42h-85v22h64q9 0 15 6t6 15v64q0 9-6 15t-15 6h-21v22h-43zM384 21q18 0 30.5 12.5T427 64v256q0 18-12.5 30.5T384 363H43q-18 0-30.5-12.5T0 320V64q0-18 12.5-30.5T43 21h341zm0 299V64H43v256h341z"
                                    fill="currentColor"
                                  ></path>
                                </svg>
                                <p className="mt-0.5 font-medium">
                                  {medio.descripcion}
                                </p>
                              </button>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="p-4 mt-4 mb-4 bg-indigo-200 rounded text-center font-bold text-gray-600 border-2 border-indigo-600 text-xl">
                    {" "}
                    No tenés facturas pendientes de tu Grupo de Pago{" "}
                  </div>
                )}
              </div>
            ) : !loadingFacturas && !hayError ? (
              <div className="flex justify-center">
                <p className="text-xl bg-green-400 py-2 px-2 font-medium rounded lg:w-3/4 2xl:w-1/2 text-white text-center mt-6 mb-6 flex flex-col lg:flex-row lg:gap-2 justify-center">
                  <p className="text-2xl lg:text-xl">🥳</p> ¡Enhorabuena! No
                  tenés nuevas facturas pendientes.{" "}
                </p>
              </div>
            ) : !loadingFacturas && hayError ? (
              <div className="flex justify-center">
                <p className="text-xl bg-red-400 py-2 px-2 font-medium rounded lg:w-3/4 2xl:w-1/2 text-white text-center mt-6 mb-6 flex flex-col lg:flex-row lg:gap-2 justify-center">
                  <p className="text-2xl lg:text-xl">🙁</p> ¡Ups! Hubo un error
                  recuperando tus facturas. Por favor, intentá más tarde.{" "}
                </p>
              </div>
            ) : null}
          </div>
        </div>
        {modalLoadingPreferencia ? (
          <ModalCarga factura={facturaSeleccionada} />
        ) : null}
      </div>
    );
  } else {
    window.location = "/";
  }
}

export default MisFacturas;
