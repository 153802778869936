import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import AuthContext from "../context/auth/authContext";

import frontiniLogo from "../assets/cardinal-color.svg";

function Nav() {
  const authContext = useContext(AuthContext);
  const { auth, logoutUser, checkPermission, puntoCobro, checkGrupo } =
    authContext;
  const menuItems = [
    { nombre: "Iniciar Sesión", link: "/login", permiso: "NO_USER" },
    { nombre: "Registrarse", link: "/register", permiso: "NO_USER" },
    { nombre: "Mis Datos", link: "/mis-datos", permiso: "USUARIO" },
    {
      nombre: "Mis Facturas",
      link: "/mis-facturas",
      permiso: "USUARIO",
    },
    { nombre: "Mis Pagos", link: "/mis-pagos", permiso: "USUARIO" },
    { nombre: "Mis Archivos", link: "/mis-archivos", permiso: "USUARIO" },
  ];
  const [menuOpen, setMenuOpen] = useState(false);
  const [userMenuOpen, setUserMenuOpen] = useState(false);
  const [showNav, setShowNav] = useState(false);

  const menuToggle = () => {
    setMenuOpen(!menuOpen);
  };

  const userMenuToggle = () => {
    setUserMenuOpen(!userMenuOpen);
  };
  const checkURL = () => {
    if (
      window.location.pathname !== "/confirmacion" &&
      window.location.pathname !== "/login2" &&
      !window.location.pathname.includes("factura-descarga")
    ) {
      setShowNav(true);
    } else {
      setShowNav(false);
    }
  };

  useEffect(() => {
    checkURL();
  });

  if (showNav) {
    return (
      <div>
        <nav className="bg-white shadow-md m-4 rounded">
          <div className="mx-auto px-2 sm:px-6 lg:px-8">
            <div className="relative flex items-center justify-between h-16">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                <button
                  className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-mainBlueLight focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                  aria-expanded="false"
                  onClick={menuToggle}
                >
                  <span className="sr-only">Abrir menu</span>
                  {menuOpen ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      className="h-5 w-5"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      className="h-5 w-5"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M4 6h16M4 12h16M4 18h16"
                      />
                    </svg>
                  )}
                </button>
              </div>
              <div className="flex-1 flex items-center justify-center sm:items-center sm:justify-between">
                <div className="flex flex-row">
                  <div className="flex-shrink-0 flex items-center">
                    <Link to="/">
                      <img
                        src={frontiniLogo}
                        className="h-9"
                        alt="frontini logo"
                      />
                    </Link>
                  </div>
                  <div className="hidden sm:block sm:ml-6 flex-wrap content-center">
                    <div className="flex space-x-4 m-main-menu">
                      {menuItems.map((item) =>
                        checkPermission(item.permiso) &&
                        item.nombre !== "Registrarse" ? (
                          <Link
                            key={item.nombre}
                            className="px-3 py-2 rounded-md font-medium text-gray-500 hover:text-white hover:bg-mainBlueLight transition duration-500 ease-in-out flex"
                            to={item.link}
                          >
                            {item.nombre}
                          </Link>
                        ) : null
                      )}
                    </div>
                  </div>
                </div>
                {checkPermission(menuItems[1].permiso) ? (
                  <Link
                    key={menuItems[1].nombre}
                    className="px-3 py-2 bg-mainBlueLight rounded-md font-medium text-white hover:bg-indigo-500 transition duration-500 ease-in-out hidden lg:block"
                    to={menuItems[1].link}
                  >
                    {menuItems[1].nombre}
                  </Link>
                ) : null}
              </div>
              {!checkPermission("NO_USER") ? (
                <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                  <div className="ml-3 relative">
                    <div>
                      <button
                        type="button"
                        onClick={userMenuToggle}
                        className="inline-flex justify-center w-full rounded-full shadow-md p-3 bg-mainBlueLight text-sm font-medium text-white focus:outline-none"
                        id="options-menu"
                        aria-haspopup="true"
                        aria-expanded="true"
                      >
                        <span className="sr-only">Menu de usuario</span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          className="w-5 h-5"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                          />
                        </svg>
                      </button>
                    </div>
                    <div
                      className={
                        userMenuOpen
                          ? "transition ease-out duration-100 transform opacity-100 scale-100"
                          : "transition ease-in duration-75 transform opacity-0 scale-0"
                      }
                    >
                      <div
                        className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white"
                        role="menu"
                        aria-orientation="vertical"
                        aria-labelledby="user-menu"
                      >
                        <button
                          type="button"
                          onClick={logoutUser}
                          className="block w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex justify-start"
                          role="menuitem"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            className="h-5 w-5 mr-4"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                            />
                          </svg>
                          Desconectar
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>

          <div className={menuOpen ? "block sm:hidden" : "hidden sm:hidden"}>
            <div className="px-2 pt-2 pb-3 space-y-1 flex flex-col">
              {menuItems.map((item) =>
                checkPermission(item.permiso) ? (
                  <Link
                    key={item.nombre}
                    className={
                      item.link === "/register"
                        ? "block px-3 py-2 rounded-md font-medium text-white bg-mainBlueLight text-center"
                        : "block px-3 py-2 rounded-md font-medium text-gray-500 hover:text-white hover:bg-mainBlueLight"
                    }
                    to={item.link}
                  >
                    {item.nombre}
                  </Link>
                ) : null
              )}
            </div>
          </div>
        </nav>
      </div>
    );
  } else {
    return <div></div>;
  }
}

export default Nav;
