import { useContext } from "react";
import axiosClient from "../../../../config/axios";
import AlertContext from "../../../../context/alerts/alertContext";
import AuthContext from "../../../../context/auth/authContext";
import UsuariosContext from "../../../../context/usuarios/usuariosContext";

function MODAL_BLOQ_DESBLOQ(props) {
  const dataModal = props.data;
  const usuariosContext = useContext(UsuariosContext);
  const { setModalBloqDesbloq, setUpdateGP, updateGP } = usuariosContext;
  const authContext = useContext(AuthContext);
  const { header } = authContext;
  const alertContext = useContext(AlertContext);
  const { setAlert } = alertContext;

  const handleAction = async () => {
    if (dataModal.elim) {
      var payload = {
        idGrupo: dataModal.persona.id,
        estado: 2,
      };
      axiosClient
        .post("set_grupo_estado", payload, header)
        .then((response) => {
          console.log(response);
          setAlert("Persona deshabilitada correctamente", "green-300");
          setUpdateGP(!updateGP);
          setModalBloqDesbloq(false);
        })
        .catch((err) => {
          setAlert("Hubo un error deshabilitando a la persona", "red-300");
          setModalBloqDesbloq(false);
          console.log(err);
        });
    } else {
      if (dataModal.bloq) {
        var payload = {
          idGrupo: dataModal.persona.id,
          estado: 2,
        };
        axiosClient
          .post("set_grupo_estado", payload, header)
          .then((response) => {
            console.log(response);
            setAlert("Persona deshabilitada correctamente", "green-300");
            setUpdateGP(!updateGP);
            setModalBloqDesbloq(false);
          })
          .catch((err) => {
            setAlert("Hubo un error deshabilitando a la persona", "red-300");
            setModalBloqDesbloq(false);
            console.log(err);
          });
      } else {
        var payload = {
          idGrupo: dataModal.persona.id,
          estado: 1,
        };
        axiosClient
          .post("set_grupo_estado", payload, header)
          .then((response) => {
            console.log(response);
            setAlert("Persona habilitada correctamente", "green-300");
            setUpdateGP(!updateGP);
            setModalBloqDesbloq(false);
          })
          .catch((err) => {
            setAlert("Hubo un error habilitando a la persona", "red-300");
            setModalBloqDesbloq(false);
            console.log(err);
          });
      }
    }
  };

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto py-10">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <div
          className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl lg:align-middle transform transition-all w-full lg:w-2/5"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="bg-white px-4 my-8 lg:my-2 pb-4 sm:p-6 sm:pb-4">
            <div className="flex justify-center">
              {dataModal.bloq || dataModal.elim ? (
                <svg className="w-10 h-10 text-red-600" viewBox="0 0 24 24">
                  <path
                    d="M10 4a4 4 0 0 0-4 4a4 4 0 0 0 4 4a4 4 0 0 0 4-4a4 4 0 0 0-4-4m7.5 9C15 13 13 15 13 17.5s2 4.5 4.5 4.5s4.5-2 4.5-4.5s-2-4.5-4.5-4.5M10 14c-4.42 0-8 1.79-8 4v2h9.5a6.5 6.5 0 0 1-.5-2.5a6.5 6.5 0 0 1 .95-3.36c-.63-.08-1.27-.14-1.95-.14m7.5.5c1.66 0 3 1.34 3 3c0 .56-.15 1.08-.42 1.5L16 14.92c.42-.27.94-.42 1.5-.42M14.92 16L19 20.08c-.42.27-.94.42-1.5.42c-1.66 0-3-1.34-3-3c0-.56.15-1.08.42-1.5z"
                    fill="currentColor"
                  ></path>
                </svg>
              ) : (
                <svg className="w-10 h-10 text-green-500" viewBox="0 0 24 24">
                  <path
                    d="M21.1 12.5l1.4 1.41l-6.53 6.59L12.5 17l1.4-1.41l2.07 2.08l5.13-5.17M10 17l3 3H3v-2c0-2.21 3.58-4 8-4l1.89.11L10 17m1-13a4 4 0 0 1 4 4a4 4 0 0 1-4 4a4 4 0 0 1-4-4a4 4 0 0 1 4-4z"
                    fill="currentColor"
                  ></path>
                </svg>
              )}
            </div>
            <div>
              <h2 className="font-bold text-xl text-center">
                {dataModal.bloq || dataModal.elim
                  ? "Deshabilitar Persona"
                  : "Habilitar Persona"}
              </h2>
              <div className="flex justify-center flex-col items-center">
                <p className="text-lg text-center text-gray-600 mt-2 mb-2">
                  {`¿Desea ${
                    dataModal.bloq
                      ? "deshabilitarse dentro del grupo de pago de la persona?"
                      : dataModal.elim
                      ? "deshabilitar a la persona de tu grupo de pago?"
                      : parseInt(localStorage.getItem("name")) ===
                        dataModal.persona.nroDocPadre
                      ? "habilitar a la persona dentro de tu grupo de pago?"
                      : "habilitarse dentro del grupo de pago de la persona?"
                  }`}{" "}
                </p>
                <p className="mb-4 italic text-gray-500">
                  {" "}
                  {dataModal.bloq
                    ? "Si lo hacés, la persona dejará de ver y recibir tus facturas"
                    : dataModal.elim
                    ? "Si lo hacés, no podrás ver ni pagar las facturas de la persona"
                    : parseInt(localStorage.getItem("name")) !==
                      dataModal.persona.nroDocPadre
                    ? "Si lo hacés, la persona podrá volver a ver y pagar tus facturas"
                    : "Si lo hacés, podrás volver a ver y pagar las facturas de la persona"}
                </p>
              </div>
              <div className="flex justify-center">
                <div className="flex flex-col justify-center items-start  border-indigo-500 border-2 rounded-lg w-1/2 py-4">
                  <p className="uppercase ml-4 flex flex-row gap-1">
                    <p className="font-bold">NOMBRE:</p>{" "}
                    {dataModal.persona.nombre}
                  </p>
                  <p className="uppercase ml-4 flex flex-row gap-1">
                    <p className="font-bold">DNI:</p>{" "}
                    {dataModal.persona.nroDocHijo}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-gray-50 flex flex-row justify-between p-6 pb-4 pt-2 gap-2">
            <button
              type="button"
              className="px-4 py-1 font-bold text-white bg-blue-500 hover:bg-blue-600 transition duration-300 rounded flex items-center lg:mb-0 w-full  justify-center"
              onClick={() => {
                handleAction();
              }}
            >
              <p className="mt-0.5">
                {dataModal.bloq
                  ? "Deshabilitar"
                  : dataModal.elim
                  ? "Deshabilitar"
                  : "Habilitar"}
              </p>
            </button>
            <button
              type="button"
              className="px-4 py-1 font-bold text-white bg-red-500 rounded flex items-center lg:mb-0 w-full justify-center hover:bg-red-600 transition duration-300"
              onClick={() => {
                setModalBloqDesbloq(false);
              }}
            >
              <p className="mt-0.5">Cerrar</p>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MODAL_BLOQ_DESBLOQ;
