import { useContext, useEffect } from "react";
import { Formik } from "formik";
import axiosClient from "../config/axios";
import UsuariosContext from "../context/usuarios/usuariosContext";
import AlertContext from "../context/alerts/alertContext";
import AuthContext from "../context/auth/authContext";
import ModalRegister from "./ModalRegister";
import { useHistory } from "react-router-dom";

function Register() {
  const usuariosContext = useContext(UsuariosContext);
  const { setModalRegister, modalRegister } = usuariosContext;
  const alertContext = useContext(AlertContext);
  const { setAlert } = alertContext;
  const authContext = useContext(AuthContext);
  const { auth, getUser } = authContext;

  let history = useHistory();

  useEffect(() => {
    getUser();
    if (auth) {
      history.push("/mis-datos");
    }
  }, [auth]);

  return (
    <div className="xl:flex-column xl:justify-center p-4 m-4 lg:m-4 lg:mt-14 lg:mb-14 shadow-xl bg-blue-800 rounded">
      <div className="mt-4">
        <h1 className="text-4xl text-center text-white font-bold ">
          Registrarse en Cardinal
        </h1>
        <p className="text-xl mt-1 text-center text-white font-medium ">
          Los campos requeridos están marcados con un *{" "}
        </p>
      </div>
      <Formik
        initialValues={{
          nombre: "",
          apellido: "",
          dni: "",
          fechaNac: "",
          email: "",
          password: "",
          confirmPass: "",
        }}
        validate={(values) => {
          const errors = {};
          if (!values.nombre) {
            errors.nombre = "El nombre es requerido";
          }
          if (!values.apellido) {
            errors.apellido = "El apellido es requerido";
          }
          if (!values.dni) {
            errors.dni = "El DNI es requerido";
          } else if (isNaN(values.dni)) {
            errors.dni = "El DNI solo puede contener dígitos";
          }
          if (!values.fechaNac) {
            errors.fechaNac = "La fecha de nacimiento es requerida";
          }
          if (!values.email) {
            errors.email = "El email es requerido";
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = "Formato de mail incorrecto";
          }
          if (!values.pass) {
            errors.pass = "La contraseña es requerida";
          } else {
            if (values.pass.length < 9) {
              errors.pass = "La contraseña debe contener al menos 9 dígitos";
            } else {
              if (!/\d/.test(values.pass)) {
                errors.pass =
                  "La contraseña debe contener al menos un dígito numérico";
              } else {
                if (values.pass !== values.confirmPass) {
                  errors.pass = "Las contraseñas no coinciden";
                  errors.confirmPass = "Las contraseñas no coinciden";
                }
                if (!values.confirmPass) {
                  errors.confirmPass = "La confirmación es requerida";
                }
              }
            }
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          var payload = {
            mail: values.email,
            nombre: values.nombre,
            apellido: values.apellido,
            fechaNac: values.fechaNac,
            nroDoc: values.dni,
            password: values.pass,
          };
          axiosClient
            .post("/v2/add_registracion", payload)
            .then((response) => {
              setModalRegister(true);
              setSubmitting(false);
            })
            .catch((err) => {
              console.log(err.response.data.data);
              if (
                err.response.data !== undefined &&
                err.response.data.data !== undefined
              ) {
                if (err.response.data.data.includes("mail")) {
                  setAlert(
                    "El mail que ingresaste ya se encuentra registrado. Por favor, ingresá uno diferente",
                    "red-300"
                  );
                }
                if (err.response.data.data.includes("usuario")) {
                  setAlert(
                    "El DNI que ingresaste ya se encuentra registrado. Por favor, ingresá uno diferente",
                    "red-300"
                  );
                }
              } else {
                setAlert(
                  "No pudimos procesar tu solicitud de registro. Por favor intentá más tarde",
                  "red-300"
                );
              }
              window.scrollTo({ top: 0 });
              setSubmitting(false);
            });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <div className="flex items-center py-12 justify-center rounded lg:p-4 lg:mb-4 ">
            <form
              onSubmit={handleSubmit}
              className="lg:grid grid-cols-2 lg:w-1/2 flex flex-wrap gap-4 justify-center items-center lg:mt-4 lg:mb-4"
            >
              <div className="w-full">
                <label
                  className="block uppercase tracking-wide text-white text-xs font-bold mb-2"
                  htmlFor="dni"
                >
                  Nombre *
                </label>
                <div className="flex flex-row w-full items-stretch relative h-15 bg-white  rounded mb-1 shadow-lg">
                  <div className="flex -mr-px justify-center w-15 px-1 bg-gray-200 rounded-l ">
                    <span className="bg-gray-200 flex items-center leading-normal px-2 border-0 text-2xl">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5 text-mainBlue"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </span>
                  </div>
                  <input
                    type="text"
                    name="nombre"
                    id="nombre"
                    className="flex-shrink flex-grow flex-auto leading-normal border-0 h-12 px-3 self-center rounded rounded-l-none relative outline-none"
                    placeholder="Tu Nombre *"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.nombre}
                    disabled={isSubmitting}
                  />
                </div>
                <p
                  className={
                    errors.nombre && touched.nombre && errors.nombre
                      ? "text-red-400 font-bold visible"
                      : "text-red-400 font-bold invisible"
                  }
                >
                  {errors.nombre && touched.nombre && errors.nombre
                    ? errors.nombre
                    : "Placeholder"}
                </p>
              </div>
              <div className="w-full">
                <label
                  className="block uppercase tracking-wide text-white text-xs font-bold mb-2"
                  htmlFor="dni"
                >
                  Apellido *
                </label>
                <div className="flex flex-row w-full items-stretch relative h-15 bg-white  mb-1 rounded shadow-lg">
                  <div className="flex -mr-px justify-center w-15 px-1 bg-gray-200 rounded-l ">
                    <span className="bg-gray-200 flex items-center leading-normal px-2 border-0 text-2xl">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5 text-mainBlue"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </span>
                  </div>
                  <input
                    type="text"
                    name="apellido"
                    id="apellido"
                    className="flex-shrink flex-grow flex-auto leading-normal border-0 h-12 px-3 w-ful self-center rounded rounded-l-none relative outline-none"
                    placeholder="Tu Apellido *"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.apellido}
                    disabled={isSubmitting}
                  />
                </div>
                <p
                  className={
                    errors.apellido && touched.apellido && errors.apellido
                      ? "text-red-400 font-bold visible"
                      : "text-red-400 font-bold invisible"
                  }
                >
                  {errors.apellido && touched.apellido && errors.apellido
                    ? errors.apellido
                    : "Placeholder"}
                </p>
              </div>
              <div className="w-full">
                <label
                  className="block uppercase tracking-wide text-white text-xs font-bold mb-2"
                  htmlFor="dni"
                >
                  DNI *
                </label>
                <div className="flex flex-row w-full items-stretch relative h-15 bg-white  mb-1 rounded shadow-lg">
                  <div className="flex -mr-px justify-center w-15 px-1 bg-gray-200 rounded-l ">
                    <span className="bg-gray-200 flex items-center leading-normal px-2 border-0 text-2xl">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5 text-mainBlue"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 2a1 1 0 00-1 1v1a1 1 0 002 0V3a1 1 0 00-1-1zM4 4h3a3 3 0 006 0h3a2 2 0 012 2v9a2 2 0 01-2 2H4a2 2 0 01-2-2V6a2 2 0 012-2zm2.5 7a1.5 1.5 0 100-3 1.5 1.5 0 000 3zm2.45 4a2.5 2.5 0 10-4.9 0h4.9zM12 9a1 1 0 100 2h3a1 1 0 100-2h-3zm-1 4a1 1 0 011-1h2a1 1 0 110 2h-2a1 1 0 01-1-1z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </span>
                  </div>
                  <input
                    type="number"
                    name="dni"
                    id="dni"
                    className="flex-shrink flex-grow flex-auto leading-normal border-0 h-12 px-3 w-ful self-center rounded rounded-l-none relative outline-none"
                    placeholder="Tu Número de DNI *"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.dni}
                    disabled={isSubmitting}
                  />
                </div>
                <p
                  className={
                    errors.dni && touched.dni && errors.dni
                      ? "text-red-400 font-bold visible"
                      : "text-red-400 font-bold invisible"
                  }
                >
                  {errors.dni && touched.dni && errors.dni
                    ? errors.dni
                    : "Placeholder"}
                </p>
              </div>
              <div className="w-full">
                <label
                  className="block uppercase tracking-wide text-white text-xs font-bold mb-2"
                  htmlFor="dni"
                >
                  Fecha de Nacimiento
                </label>
                <div className="flex flex-row w-full items-stretch relative h-15 bg-white rounded mb-1 shadow-lg">
                  <div className="flex -mr-px justify-center w-15 px-1 bg-gray-200 rounded-l ">
                    <span className="bg-gray-200 flex items-center leading-normal px-2 border-0 text-2xl">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5 text-mainBlue"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </span>
                  </div>
                  <input
                    type="date"
                    name="fechaNac"
                    id="fechaNac"
                    className="flex-shrink flex-grow flex-auto leading-normal border-0 h-12 px-3 w-ful self-center rounded rounded-l-none relative outline-none"
                    placeholder="Tu Fecha de Nacimiento *"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.fechaNac}
                    disabled={isSubmitting}
                  />
                </div>
                <p
                  className={
                    errors.fechaNac && touched.fechaNac && errors.fechaNac
                      ? "text-red-400 font-bold visible"
                      : "text-red-400 font-bold invisible"
                  }
                >
                  {errors.fechaNac && touched.fechaNac && errors.fechaNac
                    ? errors.fechaNac
                    : "Placeholder"}
                </p>
              </div>
              <div className="col-span-2 w-full">
                <label
                  className="block uppercase tracking-wide text-white text-xs font-bold mb-2"
                  htmlFor="dni"
                >
                  Email Personal *
                </label>
                <div className="flex flex-row w-full items-stretch relative h-15 bg-white rounded mb-1 shadow-lg">
                  <div className="flex -mr-px justify-center w-15 px-1 bg-gray-200 rounded-l ">
                    <span className="bg-gray-200 flex items-center leading-normal px-2 border-0 text-2xl">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5 text-mainBlue"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M14.243 5.757a6 6 0 10-.986 9.284 1 1 0 111.087 1.678A8 8 0 1118 10a3 3 0 01-4.8 2.401A4 4 0 1114 10a1 1 0 102 0c0-1.537-.586-3.07-1.757-4.243zM12 10a2 2 0 10-4 0 2 2 0 004 0z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </span>
                  </div>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    className="flex-shrink flex-grow flex-auto leading-normal border-0 h-12 px-3 w-ful self-center rounded rounded-l-none relative outline-none"
                    placeholder="Tu Email Personal *"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    disabled={isSubmitting}
                  />
                </div>
                <p
                  className={
                    errors.email && touched.email && errors.email
                      ? "text-red-400 font-bold visible"
                      : "text-red-400 font-bold invisible"
                  }
                >
                  {errors.email && touched.email && errors.email
                    ? errors.email
                    : "Placeholder"}
                </p>
              </div>
              <div className="w-full">
                <label
                  className="block uppercase tracking-wide text-white text-xs font-bold mb-2"
                  htmlFor="dni"
                >
                  Contraseña *
                </label>
                <div className="flex flex-row w-full items-stretch relative h-15 bg-white  rounded mb-1 shadow-lg">
                  <div className="flex -mr-px justify-center w-15 px-1 bg-gray-200 rounded-l ">
                    <span className="bg-gray-200 flex items-center leading-normal px-2 border-0 text-2xl">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5 text-mainBlue"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M18 8a6 6 0 01-7.743 5.743L10 14l-1 1-1 1H6v2H2v-4l4.257-4.257A6 6 0 1118 8zm-6-4a1 1 0 100 2 2 2 0 012 2 1 1 0 102 0 4 4 0 00-4-4z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </span>
                  </div>
                  <input
                    type="password"
                    name="pass"
                    id="pass"
                    className="flex-shrink flex-grow flex-auto leading-normal border-0 h-12 px-3 w-ful self-center rounded rounded-l-none relative outline-none"
                    placeholder="Tu Contraseña *"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.pass}
                    disabled={isSubmitting}
                  />
                </div>
                <p
                  className={
                    errors.pass && touched.pass && errors.pass
                      ? "text-red-400 font-bold visible"
                      : "text-red-400 font-bold invisible"
                  }
                >
                  {errors.pass && touched.pass && errors.pass
                    ? errors.pass
                    : "Placeholder"}
                </p>
              </div>
              <div className="w-full">
                <label
                  className="block uppercase tracking-wide text-white text-xs font-bold mb-2"
                  htmlFor="dni"
                >
                  Confirmar Contraseña *
                </label>
                <div className="flex flex-row w-full items-stretch  relative h-15 bg-white  rounded mb-1 shadow-lg">
                  <div className="flex -mr-px justify-center w-15 px-1 bg-gray-200 rounded-l ">
                    <span className="bg-gray-200 flex items-center leading-normal px-2 border-0 text-2xl">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5 text-mainBlue"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M18 8a6 6 0 01-7.743 5.743L10 14l-1 1-1 1H6v2H2v-4l4.257-4.257A6 6 0 1118 8zm-6-4a1 1 0 100 2 2 2 0 012 2 1 1 0 102 0 4 4 0 00-4-4z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </span>
                  </div>
                  <input
                    type="password"
                    name="confirmPass"
                    id="confirmPass"
                    className="flex-shrink flex-grow flex-auto leading-normal border-0 h-12 px-3 w-ful self-center rounded rounded-l-none relative outline-none"
                    placeholder="Confirmá tu Contraseña *"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.confirmPass}
                    disabled={isSubmitting}
                  />
                </div>
                <p
                  className={
                    errors.confirmPass &&
                    touched.confirmPass &&
                    errors.confirmPass
                      ? "text-red-400 font-bold visible"
                      : "text-red-400 font-bold invisible"
                  }
                >
                  {errors.confirmPass &&
                  touched.confirmPass &&
                  errors.confirmPass
                    ? errors.confirmPass
                    : "Placeholder"}
                </p>
              </div>
              <div className="col-span-2 w-full">
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className={
                    isSubmitting
                      ? "rounded px-2 py-2 text-xl mt-4 transform duration-200 cursor-not-allowed font-medium text-gray-800 shadow-xl bg-gray-400  w-full"
                      : "rounded px-2 py-2 text-xl mt-4 transform duration-200 hover:bg-indigo-500 font-medium text-white shadow-xl bg-mainBlueLight w-full"
                  }
                >
                  {isSubmitting ? (
                    <div className="flex flex-row justify-center items-center">
                      <p>PROCESANDO SOLICITUD</p>
                      <svg
                        className="animate-spin ml-3 h-5 w-5 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    </div>
                  ) : (
                    "REGISTRARME"
                  )}
                </button>
              </div>
            </form>
          </div>
        )}
      </Formik>
      {modalRegister ? <ModalRegister /> : null}
    </div>
  );
}

export default Register;
